@import "../../../../../scss/var";

.caseStudy {
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 992px) {
    padding: 50px 0;
  }
  .leftPage {
    border-radius: 48px;
    background: linear-gradient(225deg, #00a3ff 0%, #2dcdaf 100%);
    padding: 35px 30px;
    margin-right: -158px;
    z-index: 9;
    position: relative;
    @media only screen and (max-width: 992px) {
      margin-right: 0px;
      margin-bottom: 40px;
      flex-basis: 100%;
    }
    h6 {
      color: var(--grey-scale-900, #212121);
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.96px;
      text-transform: uppercase;
      opacity: 0.8;
    }
    h1 {
      color: $white;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 46px; /* 115% */
      padding: 12px 0;
    }
    p {
      color: $white;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    .buttons {
      gap: 20px;
      display: flex;
      @media only screen and (max-width: 992px) {
        flex-direction: column;
      }
      button {
        border-radius: 100px;
        background: $white;
        display: flex;
        height: 44px;
        padding: 0px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: var(--colours-900, #00a3ff);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 157.143% */
        border: transparent;
      }
      .actionButton {
        border-radius: 100px;
        border: 1px solid $white;
        background-color: transparent;
        color: #fff;
      }
    }
  }
  .rightPage {
    img {
      width: 100%;
    }
  }
}

.circleblue {
  position: absolute;
  right: -49px;
  bottom: 110px;
  img {
    animation: grow-and-shrink 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.circlelight {
  position: absolute;
  left: -50px;
  top: 52%;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.topLight {
  top: 26%;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
}
.greenDotAndLine {
  position: absolute;
  animation: slide 3s infinite alternate;

  right: 35px;
  top: 169px;
  @media only screen and (max-width: 1100px) {
    display: none;
  }
}
.lineWhite {
  position: absolute;
  top: 36%;
  left: 50%;
  z-index: 9;
  animation: slide 3s infinite alternate;

  @media only screen and (max-width: 1100px) {
    display: none;
  }
}

.linegrayss {
  position: absolute;
  bottom: -60px;
  left: -100px;
  right: 0;
  animation: slide 3s infinite alternate;

  text-align: center;
  @media only screen and (max-width: 1100px) {
    display: none;
  }
}
.linegrayssTop {
  position: absolute;
  top: -60px;
  left: -100px;
  animation: slide 3s infinite alternate;

  right: 0;
  text-align: center;
  @media only screen and (max-width: 1100px) {
    display: none;
  }
}

.mainPage {
  position: relative !important;
}

.grow {
  img {
    animation: grows 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}
.shrink {
  img {
    animation: grow-and-shrink 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}

@keyframes slide {
  0% {
    transform: translateX(0); /* Start position */
  }
  50% {
    transform: translateX(20px); /* Middle position */
  }
  100% {
    transform: translateX(0); /* End position */
  }
}

@keyframes grows {
  0% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}
@keyframes grow-and-shrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
