@import "../../scss/var";
.layout {
  background-color: #f6f6f6;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  align-items: center;
}
.layout_wrap {
  display: flex;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0px 0px 30px -10px rgb(37 47 63 / 37%);
  .layout_left {
    border-radius: 1rem 0 0 1rem;
    img {
      border-radius: inherit;
    }
  }
  .layout_right {
    padding: 2rem 1rem;
    // color: $titleColor;
    overflow-y: auto;
    @include min(992) {
      max-height: 484px;
    }
    @include min(1200) {
      max-height: 576px;
    }
    @include min(1400) {
      max-height: 670px;
    }
    @include min(1600) {
      max-height: 760px;
    }
  }
  @include max(991) {
    flex-wrap: wrap;
  }
}
