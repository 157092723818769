@import "../../../../../scss/common";
@import "../../../../../scss/var";

.whoWe {
  border-radius: 48px;
  background: var(--colours-dark-blue, #095b7e);
  padding: 80px 30px;
  @media only screen and (max-width: 992px) {
    padding: 50px 15px 50px;
  }
  .heading {
    h2 {
      color: $white;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px; /* 118.75% */
      padding-bottom: 10px;
      @media only screen and (max-width: 992px) {
        padding-bottom: 0;
      }
    }
    p {
      color: var(--grey-scale-500, #9e9e9e);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      @media only screen and (max-width: 992px) {
        margin-bottom: 0;
      }
    }
  }
  .box {
    border-radius: 24px;
    background: var(--opacity-white-background-5, rgba(255, 255, 255, 0.05));
    padding: 25px 25px 40px;
    @media only screen and (max-width: 992px) {
      margin-bottom: 30px;
      padding: 25px 15px 40px;
    }
    .boxImg {
      img {
        margin: 20px 0;
      }
    }

    .boxText {
      h2 {
        color: $white;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 133.333% */
      }
      h3 {
        color: $white;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
      p {
        color: var(--grey-scale-300, #bdbdbd);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }
      button {
        color: $white;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;

        border-radius: 100px;
        background: var(--colours-900, #00a3ff);
        height: 36px;
        padding: 0px 16px;
      }
    }
  }
  .weAlso {
    color: $white;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    margin-top: 40px;
    @media only screen and (max-width: 992px) {
      margin-top: 20px;
    }
  }
}
