@import "../../../scss/var";

.formWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  .formWidth {
    width: 50%;
  }
}
.formContainer {
  margin: auto;
  border: 1px solid grey;
  background-color: $primary;
  border-radius: 16px;
  // padding: 25px;

  .formHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid white;
    // box-shadow: 0px 4px 10px -8px rgba(0, 0, 0, 0.35);
    color: white;
    padding: 10px 20px 10px 20px;
    // margin-bottom: 20px;
    h3 {
      margin: 0px;
    }
  }
  hr {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  .formStep {
    padding: 25px;
    color: $grey;
  }
}


.blogBox{
    background: #fff;
    box-shadow: 0px 0px 9px 3px #00000029;
    // padding: 20px;
    border-radius: 5px;
    margin-bottom: 25px;
    margin-top: 20px;
    h4{
        font-size: 1.25rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #dee2e6;
    padding: 20px;
    }
}
.twoFieldsWrap {
    display: flex;
    flex-wrap: wrap;
    --spacing: 15px;
    padding: 0 20px 25px;
    // overflow-x: hidden;
    gap: 1.3rem 0;
    // padding-bottom: 10px;
    margin: 0 calc(-1 * var(--spacing));
    &:not(:last-child) {
      margin-bottom: 1.8rem;
      border-bottom: 1px solid #ededed;
    }
    .singleField {
      padding: 0 var(--spacing);
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .twoFlexRemove{
    display: inherit;
    .singleField{
      padding-top: 6px;
      padding-bottom: 12px;
    }
  }