@import "../../../scss/var";
@import "../../../scss/common";
.newHeaderContainer {
  background: $newdarkblue;
  padding-top: 10px;
  .newHeaderWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 20px 25px 1px rgba(20, 34, 108, 0.1);
    background: $white;
    // width: 1140px;
    padding: 12px 37px;
    align-items: center;
    border-radius: 10px;

    // margin: 10px auto;
    // margin-top: 20px;
    @media only screen and (max-width: 992px) {
      padding: 24px 20px;
    }
    a {
      img {
        width: 125px;
      }
    }
    .rightContent {
      display: flex;
      align-items: center;
      gap: 50px;
      @media only screen and (max-width: 992px) {
        display: none;
        position: absolute;
        top: 77px;
        background: #fff;
        width: 90%;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 99999;
        padding: 20px 20px 40px;
        border-radius: 0 0 20px 20px;
        border: 1px solid #eee;
      }

      ul {
        margin: 0px;
        padding: 10px 20px;
        @media only screen and (max-width: 992px) {
          padding-left: 0;
        }
        li {
          list-style-type: none;
          display: inline-block;
          padding: 10px 20px;
          position: relative;
          @media only screen and (max-width: 992px) {
            display: block;
            padding-left: 0;
          }
          a {
            text-decoration: none;
            color: #000000;
            font-size: 14px;
            font-weight: 500;
          }
          :hover {
            color: var(--colours-900, #00a3ff) !important;
          }
        }
      }
      .btns {
        display: flex;
        gap: 12px;
        flex-direction: column;
        button {
          font-weight: 800;
          font-size: 12px;
          height: 50px;
          padding: 0px 30px;
          letter-spacing: 0.5px;
        }
        p {
          color: #11afe6;
          font-size: 12px;
          display: flex;
          align-items: center;
          gap: 8px;
          justify-content: center;
          span {
            font-weight: 800;
            font-size: 16px;
            letter-spacing: -0.5px;
          }
        }
      }
    }
    .mobileMenuOpen {
      display: block;
    }
  }
}
.headerContainer {
  .headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 20px 25px 1px rgba(20, 34, 108, 0.1);
    // width: 1140px;
    padding: 2px 37px;
    align-items: center;
    border-radius: 24px;
    margin: 10px auto;
    margin-top: 20px;
    @media only screen and (max-width: 992px) {
      padding: 24px 20px;
    }
    a {
      img {
        width: 125px;
      }
    }
    .rightContent {
      display: flex;
      align-items: center;
      gap: 50px;
      @media only screen and (max-width: 992px) {
        display: none;
        position: absolute;
        top: 77px;
        background: #fff;
        width: 90%;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 99999;
        padding: 20px 20px 40px;
        border-radius: 0 0 20px 20px;
        border: 1px solid #eee;
      }

      ul {
        margin: 0px;
        padding: 10px 20px;
        @media only screen and (max-width: 992px) {
          padding-left: 0;
        }
        li {
          list-style-type: none;
          display: inline-block;
          padding: 10px 20px;
          position: relative;
          @media only screen and (max-width: 992px) {
            display: block;
            padding-left: 0;
          }
          a {
            text-decoration: none;
            color: #000000;
            font-size: 14px;
            font-weight: 500;
          }
          :hover {
            color: var(--colours-900, #00a3ff) !important;
          }
        }
      }
      .btns {
        display: flex;
        gap: 12px;
        flex-direction: column;
        button {
          font-weight: 800;
          font-size: 12px;
          height: 50px;
          padding: 0px 30px;
          letter-spacing: 0.5px;
        }
        p {
          color: #11afe6;
          font-size: 12px;
          display: flex;
          align-items: center;
          gap: 8px;
          justify-content: center;
          span {
            font-weight: 800;
            font-size: 16px;
            letter-spacing: -0.5px;
          }
        }
      }
    }
    .mobileMenuOpen {
      display: block;
    }
  }
}

.mobileMenuButton {
  display: none;
  @media only screen and (max-width: 992px) {
    display: block !important;
  }
  img {
    width: 20px;
    height: 20px;
  }
}

ul.submenu {
  display: none; /* Hide the submenu by default */
  position: absolute;
  top: 100%;
  left: 20px;
  background: #fff;
  padding: 15px 0 !important;
  z-index: 9;
  width: 100%;
  min-width: 200px;
  box-shadow: 0px 0px 7px 2px #0000002b;
  border-radius: 10px;
  z-index: 999;
  @media only screen and (max-width: 992px) {
    padding: 15px 20px !important;
  }
  li {
    width: 100%;
    color: var(--grey-scale-900, #212121);
    /* Body II - Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
}

li:hover ul.submenu {
  display: block; /* Show the submenu on parent li hover */
}

.active {
  color: var(--colours-900, #00a3ff) !important;
}

.hide_on_same_url {
  display: none !important;
}
.drop_ {
  display: block !important;
  li {
    padding-left: 10px !important;
  }
}
.first_time {
  display: none;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  li {
    // border-bottom: 1px solid #dbdbdb;
    a {
      font-size: 13px !important;
    }
  }
}
.main_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin-bottom: 0px;
    color: black;
  }
}
.mob_show {
  display: none;
}
