@import "../../../scss/var";
@import "../../../scss/common";
.headings{
  text-align: center;
  margin-top: 30px;
  h1{
    font-size: 53px;
    font-weight: 800;
    color: $black;

  }
  h2{
    background: -webkit-linear-gradient(225deg, #00a3ff 0%, #2dcdaf 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 48px;
    font-weight: 800;
    margin-top: 10px;
  }
  p{
    font-size: 16px;
    color: $black;
    font-weight: 600;
    margin: 30px 0 30px 0;
  }
} 
.whoWe {
  border-radius: 48px;
  background: var(--colours-dark-blue, #09547e);
  padding: 40px 100px 30px;
  margin-top: 80px;

  @media only screen and (max-width: 992px) {
    padding: 50px 15px 50px;
  }
  @media only screen and (max-width: 567px) {
    padding: 50px 15px 10px;
  }
 
  .heading {
    h2 {
      color: $white;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px; /* 118.75% */
      padding-bottom: 10px;
    }
    p {
      color: $white;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }
  }
  .box {
    border-radius: 24px;
    background: var(--opacity-white-background-5, rgba(255, 255, 255, 0.05));
    padding: 25px 25px 40px;
    height: auto;
    @media only screen and (max-width: 992px) {
      margin-bottom: 40px;
    }
    .boxImg {
      img {
        margin: 10px 0 20px;
      }
    }

    .boxText {
      margin-top: 12px;
      position: relative;
      h2 {
        color: $white;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 133.333% */
        margin-bottom: 18px;
      }
      h3 {
        color: $white;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
      p {
        color: $white;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        margin-bottom: 0;
      }
      .line {
        position: absolute;
        right: -80px;
        top: -50px;
        z-index: 1;
        @media only screen and (max-width: 992px) {
          display: none;
        }
      }
    }
  }
  .btnText {
    display: flex;
    justify-content: center;
    margin: 50px auto 0px;
    height: 64px;
    font-size: 13px;
    font-weight: 700;
    padding: 0px 50px;
    @media only screen and (max-width: 992px) {
      margin: 0px auto 20px;
      height: 50px;
      padding: 0px 30px;
    }
  }
}

.countText {
  border-radius: 100px;
  background: var(--colours-dark-blue, #095b7e);
  width: 32px;
  height: 32px;
  display: block;
  line-height: 32px;
  margin: -34px auto 0;
  position: relative;
  z-index: 99;
  color: $white;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.bgWhites {
  background: #fff;
  .heading {
    h2 {
      color: var(--colours-dark-blue, #095b7e);
    }
    p {
      color: var(--grey-scale-700, #616161);
    }
  }

  .box {
    // background: var(--opacity-white-background-5, rgba(255, 255, 255, 0.05));
    background: var(--grey-scale-50, #fafafa);
    .boxText {
      margin-top: 12px;
      position: relative;
      h2 {
        color: var(--colours-dark-blue, #095b7e);
      }

      p {
        color: var(--grey-scale-700, #616161);
      }
      .line {
        position: absolute;
        right: -80px;
        top: -50px;
        @media only screen and (max-width: 992px) {
          display: none;
        }
      }
    }
  }
}
