@import "../../../scss/var";
@import "../../../scss/common";

.whoWe {
  border-radius: 48px;
  background: $white;
  padding: 70px 30px 60px 30px;
  @media only screen and (max-width: 992px) {
    padding: 50px 15px 50px;
  }
  @media only screen and (max-width: 567px) {
    padding: 36px 15px 15px;
  }
  .heading {
    h1 {
      color: $newdarkblue;
      text-align: center;
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: 38px; /* 118.75% */
      padding-bottom: 22px;
    }
    p {
      color: $newdarkblue;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 24px; /* 150% */
      width: 55%;
      margin: auto;
      @media only screen and (max-width: 1100px) {
        width: 90%;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
  .box {
    border-radius: 10px;
    margin-bottom: 20px;
    max-width: 377px;
    padding: 0px 25px 5px;
    box-shadow: 0px 12px 7px -5px #ebecf3;
    height: 240px;
    @media only screen and (max-width: 992px) {
      margin-bottom: 20px;
      padding: 25px 15px 20px;
      height: 400px;
    }
    @media only screen and (max-width: 567px) {
      height: fit-content;
    }

    .boxImg {
      img {
        margin: 20px 0;
      }
    }

    .boxText {
      h2 {
        color: $newdarkblue;
        text-align: left;
        /* H7 */
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px; /* 133.333% */
      }

      p {
        color: $black;
        text-align: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        // line-height: 22px; /* 157.143% */
      }
      button {
        color: $white;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        border: none;
        border-radius: 100px;
        background: var(--colours-900, #00a3ff);
        height: 36px;
        padding: 0px 16px;
      }
    }
  }
}
.buttonsCont{
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 60px;
  @media only screen and (max-width: 992px) {
   flex-direction: column;
   margin-top: 40px;
  }
}
.LargeImg {
  border-radius: 24px;
  background: var(--opacity-blue-background-5, rgba(9, 84, 126, 0.05));
  // background-color: red;
  padding: 48px 28px;
  text-align: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  @media only screen and (max-width: 992px) {
    padding: 25px 15px;
  }
  .weAlso {
    color: var(--colours-dark-blue, #095b7e);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    margin-top: 25px;
    @media only screen and (max-width: 992px) {
      font-size: 16px;
    }
  }
  .Lineimg {
    margin-top: 28px;
  }
}
.actionButton_new {
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  border: none;
  border-radius: 100px;
  background: var(--colours-900, #00a3ff);
  height: 36px;
  padding: 0px 16px;
}
