@import "../../../scss/var";

.login {
    max-width: 440px;
    margin: auto;
  }
  .login_ByGoogle {
    flex-basis: 420px;
    .formTitle {
      margin: 1rem 0 2rem;
      img {
        width: 45px;
      }
      h2 {
        font-weight: 800;
        margin: 1.1rem 0 0.3rem;
      }
      p {
        font-weight: 400;
      }
    }
  }
  
  .formFields {
    button {
      display: block;
      width: 100%;
      margin-top: 1.8rem;
      background-color: #00a3ff;
      border-color: #00a3ff;
    }
    .formFieldSingle {
      margin-bottom: 1.2rem;
    }
    .error {
      color: red;
      padding-left: 3px;
    }
  }
  .formOptions_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    :global(.form-check-label) {
      font-weight: 300;
      font-size: 14px;
    }
  }
  .createRegister {
    margin-top: 1.4rem;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  