@import "../../scss/var";

.customBtn {
  align-items: center;
  gap: 4px;
  outline: none;
  border: 1px solid transparent;
  padding: 0px 20px;
  height: 44px;
  &:global {
    &.btn-primary {
      border-color: transparent;
      color: #ffffff;
      border-radius: 10rem;
      background-color: $secondary;
    }
    &.btn-outline-primary {
      color: $secondary;
      border-radius: 10rem;
      border-color: $secondary;
      background-color: transparent;
    }
    &.btn-bg-primary {
      border-color: transparent;
      color: #ffffff;
      border-radius: 10rem;
      background-color: $primary;
      white-space: nowrap;
    }
    &.btn-outColor-primary {
      color: $primary;
      border-radius: 10rem;
      border-color: $primary;
      background-color: transparent;
      white-space: nowrap;
    }
    &.btn-bgGrey-primary {
      border-color: transparent;
      color: $primary;
      border-radius: 10rem;
      background: var(--opacity-blue-background-5, rgba(9, 84, 126, 0.05));
    }
    &.btn-noColor-primary {
      color: $secondary;
      border-radius: 10rem;
      border: none;
      background-color: transparent;
    }
    &.btn-contact-primary {
      color: #ffffff;
      border-radius: 10rem;

      background-color: $secondary;
      padding: 0px 24px;
      height: 44px;
      margin-top: 24px;
    }
  }
}

.test {
  padding: 0 12px;
}
