@import "../../../../scss//var";
@import "../../../../scss//common";

.blogArticleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px auto 0px auto;
  .registerContent {
    text-align: center;
    margin-top: 40px;
    span {
      color: $grey;
      font-style: normal;
      font-weight: 800;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    h1 {
      font-style: normal;
      font-weight: 600;
      line-height: 46px; /* 115% */
      color: $primary;
      text-transform: capitalize;
    }
    p {
      color: var(--grey-scale-500, #9e9e9e);
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
  .userArticleWrapper {
    @media only screen and (max-width: 992px) {
      display: none;
    }
    .usersArticle {
      display: flex;
      align-items: center;
      gap: 40px;
      margin-top: 40px;
      .users {
        color: $primary;
        display: flex;
        gap: 2px;
        align-items: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
    .categoryWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      margin-top: 20px;
      .categoryText {
        color: $primary;
        font-weight: 500;
        font-size: 16px;
      }
      span {
        button {
          color: $primary;
          font-weight: 600;
          font-size: 14px;
          cursor: pointer;
          text-transform: capitalize;
          &:active {
            background-color: rgba(9, 84, 126, 0.05);
            border-color: transparent;
          }
        }
      }
    }
  }
}

.goodHands {
  margin: 30px auto 0px auto;
  max-width: 864px;
  @media only screen and (max-width: 992px) {
    margin-top: 20px;
  }
  h5 {
    color: $primary;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    @media only screen and (max-width: 992px) {
      br {
        display: none;
      }
      font-size: 16px;
      line-height: 26px;
    }
  }
  p {
    color: var(--grey-scale-700, #616161);
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    // margin: 0px;
    @media only screen and (max-width: 992px) {
      br {
        display: none;
      }
    }
  }
  .onlineAccountants {
    margin: 40px 0px;
  }
  .goodhandsImg {
    margin-top: 40px;
  }
}

.card {
  background: var(--grey-scale-50, #fafafa);
  padding: 24px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 40px;
  h6 {
    color: $primary;
  }
}
.cardSecond {
  background: var(--grey-scale-50, #fafafa);
  border-radius: 24px;
  padding: 40px;
  .commaAndText {
    display: flex;
  }
}
.thirdSection {
  background: var(--grey-scale-50, #fafafa);
  padding: 24px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 40px;
}
.card_img {
  @media only screen and (max-width: 992px) {
    text-align: center;
    margin-bottom: 20px;
  }
}
.bottomCard {
  background: var(--grey-scale-50, #fafafa);
  border-radius: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media only screen and (max-width: 992px) {
    margin-bottom: 20px;
  }

  .bottomCardImg {
    display: flex;
    align-items: center;
    gap: 16px;

    p {
      color: $primary;
    }
    .small {
      color: $secondary;
    }
  }
}
.bottomCard2 {
  background: var(--grey-scale-50, #fafafa);
  border-radius: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  h6 {
    color: var(--grey-scale-900, #212121);
  }
  label {
    color: #616161;
  }
  input {
    border-radius: 8px;
    border: 1px solid var(--grey-scale-100, #e0e0e0);
    background: $white;
    display: flex;
    padding: 12px;
    align-items: center;
    outline: none;
    width: 100%;
  }
}
.commaAndText {
  display: flex;
  align-items: baseline;
  gap: 8px;
}
.lastSection {
  :global {
    .whoWe {
      background-color: $primary;
      padding: 80px 0px;
      margin: 100px 0;
      @media only screen and (max-width: 992px) {
        margin: 50px 0;
        padding: 50px 0px;
      }
      .heading {
        color: $white;
        h2 {
          color: inherit;
        }
        p {
          color: $grey;
        }
      }
    }
  }
}

.image {
  width: 100%;
  img {
    width: 100%;
    height: 590px;
    object-fit: cover;
    border-radius: 15px;
  }
}
