@import "../../../scss/var";
@import "../../../scss/common";

.whoWe {
  border-radius: 48px;
  // background: var(--colours-dark-blue, #095b7e);
  padding: 80px 30px 50px;
  @media only screen and (max-width: 992px) {
    padding: 50px 15px 50px;
  }
  @media only screen and (max-width: 567px) {
    padding: 20px 15px 20px;
  }
  .heading {
    h1 {
      color: $darkblue;
      text-align: center;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px; /* 118.75% */
      padding-bottom: 20px;
      @media only screen and (max-width: 992px) {
        padding-bottom: 0;
      }
    }
    p {
      color: $darkblue;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      @media only screen and (max-width: 992px) {
        margin-bottom: 0;
      }
    }
  }
  .box {
    border-radius: 24px;
    background: $darkblue;
    padding: 25px 25px 40px;
    @media only screen and (max-width: 992px) {
      margin-bottom: 30px;
      padding: 25px 15px 40px;
    }
    .boxImg {
      img {
        margin: 20px 0;
      }
    }

    .boxText {
      h2 {
        color: $white;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px; /* 133.333% */
        margin-bottom: 30px;
      }
      h3 {
        color: $white;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        margin-bottom: 30px;
      }
      p {
        color: $white;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.37em;
        margin: auto;
        margin-bottom: 20px;
      }
      button {
        color: var(--colours-900, #00a3ff);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        border: none;
        border-radius: 100px;
        background: #fff;
        height: 54px;
        padding: 0px 40px;
      }
    }
  }
  .weAlso {
    color: $darkblue;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    margin-top: 30px;
    padding: 0 100px;
    @media only screen and (max-width: 992px) {
      margin-top: 20px;
      padding: 0 0px;
    }
  }
}
