@import "../../../../scss/var";
@import "../../../../scss/common";
@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");

.contactUs {
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 992px) {
    padding: 50px 0;
  }

  .leftPage {
    border-radius: 48px;
    background: linear-gradient(225deg, #00a3ff 0%, #2dcdaf 100%);
    padding: 50px 30px;
    margin-right: -158px;
    z-index: 9;
    position: relative;
    @media only screen and (max-width: 992px) {
      margin-right: 0px;
      margin-bottom: 40px;
      flex-basis: 100%;
    }

    h6 {
      color: var(--grey-scale-900, #212121);
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.96px;
      text-transform: uppercase;
      opacity: 0.8;
    }
    h1 {
      color: $white;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 46px; /* 115% */
      padding: 12px 0;
    }
    p {
      color: $white;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    .buttons {
      gap: 20px;
      display: flex;
      @media only screen and (max-width: 992px) {
        flex-direction: column;
      }
      button {
        border-radius: 100px;
        background: $white;
        display: flex;
        height: 44px;
        padding: 0px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: var(--colours-900, #00a3ff);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 157.143% */
        border: transparent;
      }
      .actionButton {
        border-radius: 100px;
        border: 1px solid $white;
        background-color: transparent;
        color: #fff;
      }
    }
  }
  .rightPage {
    img {
      width: 100%;
    }
  }
}

.contactFormContainer {
  background-color: $primary;
  padding: 80px 0px;
  border-radius: 48px;
  @media only screen and (max-width: 768px) {
    padding: 50px 40px 50px;
  }

  h2 {
    color: #ffffff;
    text-align: center;
    margin-bottom: 12px;
  }
  p {
    color: $grey;
    text-align: center;
    margin-bottom: 40px;
  }
  .formContainer {
    background-color: #ffffff;

    padding: 48px;
    border-radius: 48px;
    display: flex;
    flex-direction: column;
    max-width: 909px;
    margin: 0 auto;

    h5 {
      color: $primary;
      font-weight: 600;
      margin: 0px 0 10px;
    }
    span {
      color: $grey;
      font-weight: 500;
      text-align: center;
    }

    .formWrapper {
      margin: 24px 0px;

      .label {
        font-size: 14px;
        color: var(--grey-scale-700, #616161);
        margin: 0px;
        @media only screen and (max-width: 768px) {
          margin-top: 6px;
        }
      }
      input {
        margin-top: 4px;
        border-radius: 8px;
      }
      .placeholder {
        font-size: 14px;
        box-shadow: none;
        border-radius: 8px;
        padding: 12px;
        &::placeholder {
          color: var(--grey-scale-500, #9e9e9e);
          border-radius: 8px;
        }
      }
    }
  }
}

.MapSection {
  padding: 80px 0;
  @media screen and (max-width: 567px) {
    padding: 39px 0;
  }
  .officeHeading {
    text-align: center;
    h2 {
      color: var(--colours-dark-blue, #095b7e);
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px; /* 118.75% */
    }
    p {
      color: var(--grey-scale-700, #616161);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
  }
  .maps {
    iframe {
      border-radius: 16px;
    }
  }
  .officeText {
    border-radius: 48px;
    background: var(--grey-scale-50, #fafafa);
    padding: 40px 30px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: 992px) {
      margin-top: 40px;
    }
    .innerText {
      h3 {
        color: var(--colours-dark-blue, #095b7e);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 133.333% */
        padding-top: 5px;
      }
      p {
        color: var(--grey-scale-700, #616161);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 5px;
        line-height: 22px; /* 157.143% */
      }
    }
  }
  .contactFooter {
    padding: 40px 0px;
    border-radius: 48px;
    margin-top: 12px;
    background-color: #fafafa;
    @media only screen and (max-width: 992px) {
      margin-top: 90px;
    }

    .contactInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 40px;
      @media only screen and (max-width: 992px) {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .contact1 {
        background-color: #ffffff;
        padding: 0px 16px;
        border-radius: 48px;
        height: 36px;
        display: flex;
        align-items: center;
        color: $primary;
        font-size: 14px;

        span {
          color: $grey;
          font-size: 14px;
        }
      }
    }
  }
}

.circleblue {
  position: absolute;
  right: -49px;
  bottom: 110px;
  img {
    animation: grow-and-shrink 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.circlelight {
  position: absolute;
  left: -50px;
  top: 52%;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
  @media screen and (max-width: 1400px) {
    top: 42%;
  }
}

.topLight {
  top: 31%;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
}
.greenDotAndLine {
  position: absolute;
  right: 35px;
  animation: slide 3s infinite alternate;

  top: 169px;
  @media only screen and (max-width: 1100px) {
    display: none;
  }
}
.lineWhite {
  position: absolute;
  top: 36%;
  left: 50%;
  z-index: 9;
  animation: slide 3s infinite alternate;

  @media only screen and (max-width: 1100px) {
    display: none;
  }
}

.linegrayss {
  position: absolute;
  bottom: -60px;
  left: -100px;
  right: 0;
  text-align: center;
  animation: slide 3s infinite alternate;

  @media only screen and (max-width: 1100px) {
    display: none;
  }
}
.linegrayssTop {
  position: absolute;
  top: -60px;
  left: -100px;
  right: 0;
  text-align: center;
  animation: slide 3s infinite alternate;

  @media only screen and (max-width: 1100px) {
    display: none;
  }
}
.formLabel {
  font-size: 14px;
  color: var(--grey-scale-700, #616161);
  margin: 0px;
}

.fieldWrapper {
  margin-top: 5px;
  input {
    font-size: 14px;
    box-shadow: none;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 12px;
    &::placeholder {
      color: var(--grey-scale-500, #9e9e9e);
      border-radius: 8px;
    }
  }
  textarea {
    font-size: 14px;
    box-shadow: none;
    border-radius: 8px;
    padding: 12px;
    &::placeholder {
      color: var(--grey-scale-500, #9e9e9e);
      border-radius: 8px;
    }
  }
}
.customButton {
  button {
    color: #ffffff;
    border-radius: 10rem;
    background-color: #00a3ff;
    padding: 0px 24px;
    height: 44px;
    margin-top: 24px;
    border: 0;
    width: 100%;
  }
}

.mainPage {
  position: relative !important;
}

.grow {
  img {
    animation: grows 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}
.shrink {
  img {
    animation: grow-and-shrink 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}

@keyframes slide {
  0% {
    transform: translateX(0); /* Start position */
  }
  50% {
    transform: translateX(20px); /* Middle position */
  }
  100% {
    transform: translateX(0); /* End position */
  }
}

@keyframes grows {
  0% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}
@keyframes grow-and-shrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
