@import "../../../../scss/var";

.homePage {
  padding: 80px 0 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 992px) {
    padding: 50px 0;
  }
  .leftPage {
    border-radius: 28px;
    background: linear-gradient(225deg, #00a3ff 0%, #2dcdaf 100%);
    padding: 35px 30px;
    margin-right: -158px;
    z-index: 9;
    position: relative;
    width: 600px;
    @media only screen and (min-width: 1400px) {
      width: 660px;
    }
    @media only screen and (max-width: 992px) {
      margin-right: 0px;
      margin-bottom: 40px;
      width: 100%;
    }
    h6 {
      color: var(--grey-scale-900, #212121);
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.96px;
      text-transform: uppercase;
      opacity: 0.8;
    }
    h1 {
      color: $white;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 46px; /* 115% */
      padding: 12px 0;
    }
    p {
      color: $white;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
    .buttons {
      gap: 20px;
      display: flex;
      @media only screen and (max-width: 567px) {
        flex-direction: column;
      }
      button {
        border-radius: 100px;
        background: $white;
        display: flex;
        height: 44px;
        padding: 0px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: var(--colours-900, #00a3ff);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 157.143% */
        border: transparent;
        width: 180px;

        @media only screen and (max-width: 567px) {
          width: 100%;
        }
      }
      .actionButton {
        border-radius: 100px;
        border: 1px solid $white;
        background-color: transparent;
        color: #fff;
      }
    }
  }
  .rightPage {
    img {
      width: 100%;
    }
  }
}

.WhyChoose {
  padding: 80px 0;
  border-radius: 48px;
  background: var(--grey-scale-50, #fafafa);
  overflow: hidden;
  @media only screen and (max-width: 992px) {
    padding: 50px 0;
  }
  .slickSlider {
    h2 {
      text-align: center;
      margin-bottom: 40px;
      color: var(--colours-dark-blue, #095b7e);
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px; /* 118.75% */
      @media only screen and (max-width: 992px) {
        margin-bottom: 20px;
      }
    }
  }
  .chosseBoxImg {
    img {
      margin-bottom: 25px;
    }
  }

  .chosseBox {
    border-radius: 24px;
    background: $white;
    padding: 30px 25px;
    width: 95% !important;
    @media only screen and (max-width: 992px) {
      width: 100% !important;
    }
    .chooseText {
      margin-top: 160px;
      @media only screen and (max-width: 992px) {
        margin-top: 40px;
      }
      h2 {
        color: var(--colours-dark-blue, #095b7e);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 120% */
      }
      p {
        color: var(--grey-scale-700, #616161);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        margin-top: 12px;
      }
    }
  }
}

.slickSliderMAin {
  margin-left: 80px;
  @media only screen and (max-width: 992px) {
    width: 95%;
    margin: 0 auto;
  }
}

.longTerm {
  margin-top: 50px;
  text-align: center;
  border-radius: 24px;
  background: var(--opacity-blue-background-5, rgba(9, 84, 126, 0.05));
  padding: 48px 15px;
  .line {
    margin: 10px 0;
  }
  p {
    margin-top: 20px;
    color: var(--colours-dark-blue, #095b7e);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
  }
}
.officeText {
  border-radius: 48px;
  background: var(--grey-scale-50, #fafafa);
  padding: 40px 30px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: 992px) {
    margin-top: 40px;
  }
  .innerText {
    h3 {
      color: var(--colours-dark-blue, #095b7e);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 133.333% */
      padding-top: 5px;
    }
    p {
      color: var(--grey-scale-700, #616161);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 5px;
      line-height: 22px; /* 157.143% */
    }
  }
}

.circleblue {
  position: absolute;
  right: -49px;
  bottom: 140px;
  img {
    animation: grow-and-shrink 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.circlelight {
  position: absolute;
  left: -50px;
  top: 50%;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
  @media screen and (max-width: 1400px) {
    top: 50%;
  }
}

.topLight {
  top: 30%;
  @media screen and (max-width: 1400px) {
    top: 27%;
  }
}
.lineWhite {
  position: absolute;
  top: 36%;
  left: 50%;
  animation: slide 3s infinite alternate;

  z-index: 9;
  @media only screen and (max-width: 1100px) {
    display: none;
  }
}

.linegrayss {
  position: absolute;
  bottom: -60px;
  left: -100px;
  animation: slide 3s infinite alternate;

  right: 0;
  text-align: center;
  @media only screen and (max-width: 1100px) {
    display: none;
  }
}
.linegrayssTop {
  position: absolute;
  top: -60px;
  left: -100px;
  right: 0;
  animation: slide 3s infinite alternate;

  text-align: center;
  @media only screen and (max-width: 1100px) {
    display: none;
  }
}

.HiBooksCompany {
  border-radius: 48px;
  background: $white;
  padding: 0px 30px 80px;
  h1 {
    color: var(--colours-dark-blue, #095b7e) !important;
  }
  @media only screen and (max-width: 992px) {
    padding: 50px 15px 50px;
  }
  @media only screen and (max-width: 992px) {
    padding: 6px 15px 15px;
  }
}

.banRightLine {
  text-align: right;
  position: absolute;
  right: 60px;
  top: 150px;
  animation: slide 3s infinite alternate;

  @media only screen and (max-width: 1100px) {
    display: none;
  }
}

.HiBooksQuete {
  margin-bottom: 50px;
  @media screen and (max-width: 567px) {
    margin-bottom: 20px;
  }
}

.AccordionsSection {
  margin-bottom: 0px;
}

.mainPage {
  position: relative !important;
}

.grow {
  img {
    animation: grows 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}
.shrink {
  img {
    animation: grow-and-shrink 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}

@keyframes slide {
  0% {
    transform: translateX(0); /* Start position */
  }
  50% {
    transform: translateX(20px); /* Middle position */
  }
  100% {
    transform: translateX(0); /* End position */
  }
}

@keyframes grows {
  0% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}
@keyframes grow-and-shrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
