@import "../../../scss/var";
@import "../../../scss/common";

.whoWe {
  border-radius: 48px;
  background: var(--colours-dark-blue, #095b7e);
  padding: 80px 30px;
  @media only screen and (max-width: 992px) {
    padding: 50px 15px 50px;
  }
  .heading {
    h1 {
      color: $white;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px; /* 118.75% */
      padding-bottom: 10px;
      @media only screen and (max-width: 992px) {
        padding-bottom: 0;
      }
    }
    p {
      color: var(--grey-scale-500, #9e9e9e);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      @media only screen and (max-width: 992px) {
        margin-bottom: 0;
      }
    }
  }
  .box {
    border-radius: 24px;
    background: var(--opacity-white-background-5, rgba(255, 255, 255, 0.05));
    padding: 25px 25px 25px;
    margin-bottom: 20px;
    height: 290px;
    @media only screen and (max-width: 992px) {
      margin-bottom: 30px;
      padding: 25px 15px 40px;
    }
    .boxImg {
      text-align: left;
      img {
        margin: 0px 0 20px;
        width: 56px;
        height: 56px;
      }
    }

    .boxText {
      text-align: left;
      h2 {
        color: $white;
        text-align: left;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 133.333% */
        min-height: 50px;
        @media only screen and (max-width: 992px) {
          min-height: inherit;
        }
      }

      p {
        color: var(--grey-scale-300, #ffffff);
        text-align: left;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        min-height: 50px;
        max-height: auto;
      }
      img {
        @media screen and (max-width: 576px) {
          display: none;
        }
      }
    }
  }
}

.longterm {
  border-radius: 24px;
  background: var(--opacity-white-background-5, rgba(255, 255, 255, 0.05));
  display: flex;
  padding: 48px 28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  margin-top: 20px;
  h4 {
    color: #fff;
    margin: 0;
    font-size: 22px;
  }
  p {
    color: $white;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 133.333% */
    width: 60%;
    @media only screen and (max-width: 992px) {
      width: 100%;
      br {
        display: none;
      }
    }
  }
}
