// colors
$primary: #095b7e;
$secondary: #11afe6;
$grey: #9e9e9e;
$white: #fff;
$black: #000000;
$darkblue: #09547e;
$newdarkblue:#09547e;

// responsive widths
$sm: 374;
$md: 424;
$lg: 767;
$xl: 1023;
$xl1: 1439;
$xl2: 1023;
$xl3: 1439;
$xl5: 1599;

// responsive media functions
@mixin min($value) {
  @media screen and (min-width: ($value + 1 + "px")) {
    @content;
  }
}
@mixin max($value) {
  @media screen and (max-width: ($value + "px")) {
    @content;
  }
}
@mixin minMax($min, $max) {
  @media (min-width: ($min + "px")) and (max-width: ($max + "px")) {
    @content;
  }
}

// make item center
@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

// custom responsive flex container
@mixin flexContainer($width, $space, $gap, $jc) {
  --width: #{$width};
  --space: #{$space};
  --gap: #{$gap};
  --jc: #{$jc};
  display: flex;
  flex-wrap: wrap;
  justify-content: var(--jc);
  margin: 0 calc(-1 * var(--space));
  gap: var(--gap) 0;
  > * {
    padding: 0 var(--space);
    flex-basis: var(--width);
    max-width: var(--width);
  }
}

.hoverBtn {
  button {
    transition: transform 0.4s ease;
    &:hover {
      box-shadow: 0 10px 18px 0 rgba(26, 54, 96, 0.18);
      transform: translateY(-2px);
      transition: transform 0.4s ease;
    }
  }
}
.hoverDivWhite {
  transition: transform 0.4s ease;
  transition: box-shadow 0.4s ease;

  &:hover {
    box-shadow: 9px 12px 20px -9px rgb(0 0 0 / 52%);
    transform: translateY(-2px);
    transition: transform 0.4s ease;
    transition: box-shadow 0.4s ease;
  }
}

.hoverFaq {
  transition: transform 0.4s ease;
  transition: box-shadow 0.4s ease;

  &:hover {
    box-shadow: 9px 12px 20px -15px rgb(0 0 0 / 52%);
    transform: translateY(-2px);
    transition: transform 0.4s ease;
    transition: box-shadow 0.4s ease;
  }
}

.hoverDiv {
  transition: transform 0.4s ease;
  transition: box-shadow 0.4s ease;
  cursor: pointer;
  &:hover {
    box-shadow: 9px 12px 20px -9px rgb(147, 155, 163);
    transform: translateY(-2px);
    transition: transform 0.4s ease;
    transition: box-shadow 0.4s ease;
  }
}
