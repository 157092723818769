@import "./var";
@import "./common";
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

.sliderCustom .slick-prev,
.sliderCustom .slick-next {
  width: 60px;
  height: 60px;
}

body {
  font-family: "Manrope", sans-serif !important;
  letter-spacing: 0.5px;
}

.sliderCustom button.slick-arrow {
  background-image: url(./../assests/homePage/Small.svg);
  background-repeat: no-repeat;
  z-index: 999;
  background-position: center;
  background-size: auto;
  @media only screen and (max-width: 992px) {
    opacity: 0;
  }
}

.sliderCustom button.slick-prev {
  @media only screen and (max-width: 992px) {
    left: 0px !important;
  }
}

.sliderCustom button.slick-prev:before {
  content: none !important;
}
.sliderCustom button.slick-next {
  right: -11px !important;
  transform: rotate(180deg);
  top: 40%;
  .videoSlider button.slick-arrow.slick-prev {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  h1 {
    font-size: 24px !important;
    line-height: 34px !important;
  }
  h2 {
    font-size: 20px !important;
    .videoSlider button.slick-arrow.slick-prev {
      display: none !important;
    }
    line-height: 30px !important; /* 118.75% */
    padding-bottom: 0 !important;
  }
  p {
    font-size: 14px !important;
  }
  .form-check {
    .form-check-label {
      margin-bottom: 0 !important;
      .slick-slider {
        margin-bottom: 56px;
      }
    }
  }
  .col-md-5,
  .col-md-7,
  .col-md-6,
  .col-md-4,
  .col-md-3 {
    width: 100%;
  }
  .col-md-5 {
    justify-content: center;
  }
}

@media only screen and (max-width: 992px) {
  .mobileResponsive {
    flex-direction: column-reverse;
  }
}

.videoSlider button.slick-arrow {
  background-image: url(./../assests/forwardIcon.svg);
  background-repeat: no-repeat;
  z-index: 999;
  background-position: center;
  background-size: auto;
  right: -9px !important;
  transform: rotate(360deg);
  top: 40%;
  @media only screen and (max-width: 992px) {
    opacity: 0;
  }
}

.videoSlider button.slick-arrow.slick-prev {
  display: none !important;
}

.videoSlider .slick-slider {
  margin-bottom: 18px;
}

.videoSlider .slick-prev:before,
.slick-next:before {
  color: transparent !important;
}

// ========= theme Table ============
.theme_table {
  font-size: 14px;
  margin-top: 20px;
  overflow: auto;
  .table {
    // border-color: $primaryLight;
    // border-collapse: separate;
    // border-spacing: 0 10px;
    & > :not(caption) > * > * {
      border-bottom-width: 0;
      padding: 0.7rem 0.7rem;
    }
    tr {
      background-color: #fff;
      box-shadow: 0px 0px 1px 1px #e0e0e0;
    }
    th {
      font-weight: 500;
      background-color: rgba(56, 90, 100, 0.2196078431);
      color: #323232;
      img {
        width: 28px;
        height: 28px;
      }
    }
    td {
      color: #525252;
      vertical-align: middle;
      img {
        width: 45px;
        height: 45px;
        object-fit: cover;
      }
    }
  }
}
// ========= theme Table end ============

// ======== theme dropdown menu =========
.themeDropdown_menu {
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(107, 108, 126, 0.25);
  border: none;
  .themeDropdown_menuIcon {
    margin-right: 12px;
    font-size: 1.1em;
  }
  li,
  a {
    padding: 6px 14px;
    white-space: nowrap;
    transition: 0.2s linear;
    font-size: 14px;
    cursor: pointer;
    color: #525252;
    &:hover {
      background-color: #f6f6f6;
    }
  }
}
// ======== theme dropdown menu end=========
// ======== theme Drawer ===================
.theme_drawer {
  &.offcanvas {
    max-width: 360px;
  }
}
// ======== theme Drawer end================

.loadingLines {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

// ======= tabs ==========
.theme_tabs {
  .nav-tabs {
    font-size: 14px;
    border-color: #f2f2f2;
    .nav-link {
      font-weight: 500;
      border: none;
      border-bottom: 1px solid transparent;
      &.active {
        color: $secondary;
        border-color: currentColor;
        background-color: transparent;
      }
    }
  }
}
.theme_box {
  // @include box;
  height: 100%;
}

#dropdown-basic {
  border-color: #00a3ff;
  background: #00a3ff;
  color: #fff;
}

button.addBlogs.btn.btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  font-size: 14px;
  font-weight: 700;
  background-color: #00a3ff;
  border-color: #00a3ff;
  span {
    margin-right: 5px;
    font-size: 16px;
  }
}

.ql-editor.ql-blank {
  height: 100px;
}

.ck.ck-content.ck-editor__editable {
  height: 120px;
}

.ql-container.ql-snow {
  height: 120px;
}
.error {
  color: red;
  font-size: 14px;
}
button#dropdown-basic {
  background: transparent;
  border: none;
  display: flex;
  color: #222;
}

.dropdown-toggle {
  &:after {
    width: 0;
    height: 0;
    border: 0;
  }
}
span.badge.bg-info {
  background-color: #095b7e !important;
}

.active > .page-link,
.page-link.active {
  background: #095b7e !important;
  border-color: #095b7e !important;
  color: #fff !important;
}

.page-link {
  color: #095b7e !important;
  span {
    font-size: 16px !important;
  }
}

.page-link:focus {
  box-shadow: none !important;
}

td h4 span.spinner-border.spinner-border-sm {
  width: 2rem !important;
  height: 2rem !important;
  border-color: #00a3ff !important;
  border-right-color: transparent !important;
}

._error-inner {
  color: red;
}

._form-thank-you {
  font-size: 24px;
  color: #136b9d;
  margin: 25px 0;
  font-weight: 600;
  text-align: center;
}
._row._checkbox-radio span {
  position: relative;
  top: -2px;
  left: 4px;
}

.comanbox {
  align-items: center;
  span {
    label {
      margin-bottom: -5px !important;
    }
  }
}

._submit span.spinner-border.spinner-border-sm {
  color: #fff;
  margin-right: 8px;
}
.disblogs p {
  margin: 0;
}
.sliderCustomBlog {
  .slick-slider {
    width: 100%;
    // margin-left: 4px;
    .slick-prev:before {
      display: none;
    }
    .slick-list {
      margin-left: 15px;
    }
    .slick-prev {
      background-repeat: no-repeat;
      background-size: 50px;
      right: -70px;
      background-image: url("../assests/forward.svg");

      background-position: center;
      width: 50px;
      height: 50px;
      transform: rotate(179deg);
      top: -6px;
      box-shadow: none !important;

      left: -42px;
      // @media only screen and (max-width: 767px) {
      //   display: none !important;
      // }
    }
    .slick-next {
      background-repeat: no-repeat;
      background-size: 50px;
      right: -70px;
      background-image: url("../assests/forward.svg");
      background-position: center;
      width: 50px;
      height: 50px;
      top: -8px !important;
      box-shadow: none !important;
      transform: none !important;
      transition: none !important;
      @media only screen and (max-width: 567px) {
        right: -58px !important;
      }
    }
  }
  .slick-slide {
    @media screen and (min-width: 767px) and (max-width: 800px) {
      width: 290px !important;
      margin-left: 10px;
    }
  }
}

.modalVideos {
  .modal-body {
    height: 430px;
  }
}

.buttonCalls {
  border-radius: 100px;
  margin: 20px auto 5px;
  background: var(--colours-900, #00a3ff);
  display: flex;
  height: 44px;
  padding: 0px 24px;
  justify-content: center;
  color: #fff;
  align-items: center;
  align-self: stretch;
  border: none;
  margin-bottom: 20px;
}

.discriptionsss table {
  width: 100%;
  border: 1px solid;
  margin: 10px 0;
  th {
    border: 1px solid;
    padding: 10px;
  }
  td {
    border: 1px solid;
    padding: 10px;
  }
}

.form-group {
  h4 {
    font-size: 20px;
    padding-bottom: 10px;
  }
}

.discriptionsss {
  p {
    a {
      color: #1155cc;
      text-decoration: underline;
    }
  }
}
.react-player__shadow {
  background: transparent !important;
}

.freeBookKeeping_centerImg__JRlGA iframe {
  height: 100% !important;
}
body .custom-crisp-chatbox {
  position: fixed !important;
  bottom: 80px !important;
  /* Add any other styles you need */
}
body .crisp-client .cc-tlyw .cc-kxkl .cc-nsge {
  /* Override default styles */
  z-index: 9999 !important;
  display: flex !important; /* For example, change display to flex */
  bottom: 80px !important;
  border-radius: 0 !important; /* For example, change border-radius to 0 */
}

@media (max-width: 567px) {
  body .crisp-client .cc-tlyw[data-full-view="true"] .cc-kxkl .cc-nsge {
    /* Override default styles */
    z-index: 9999 !important;
    display: flex !important; /* For example, change display to flex */
    bottom: 80px !important;
    border-radius: 0 !important; /* For example, change border-radius to 0 */
  }
}
