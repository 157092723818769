@import "var";
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
img {
  max-width: 100%;
}
h1 {
  font-size: 40px;
  @include max($xl) {
    font-size: 36px;
  }
}
h2 {
  font-size: 32px;
  @include max($xl) {
    font-size: 36px;
  }
  @include max($lg) {
    font-size: 30px;
  }
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 18px;
}
p {
  font-size: 16px;
}
li {
  font-size: 14px;
}
// span {
//   font-size: 12px;
// }
.small {
  font-size: 14px;
}

body {
  font-family: "Manrope", sans-serif !important;
}

.container {
  @media screen and (min-width: 1400px) {
    max-width: 1199px;
  }
}
@media screen and (max-width: 1024px) {
  .crisp-client .cc-tlyw .cc-kxkl .cc-nsge {
    bottom: 80px !important;
  }
}
@media screen and (max-width: 567px) {
  .crisp-client .cc-tlyw[data-full-view="true"] .cc-kxkl .cc-nsge {
    bottom: 80px !important;
  }
}
// .crisp-client .cc-tlyw[data-full-view="true"] .cc-kxkl .cc-nsge .cc-imbb {
//   margin-bottom: 75px !important;
// }
.crisp-client .cc-tlyw .cc-kxkl .cc-nsge {
  // bottom: 84px !important;
}

// .cc-imbb.cc-qfnu {
//   margin-bottom: 70px !important;
// }
