@import "../../../scss/var";
.bankcontainer {
  background: rgba(9, 84, 126, 0.05);
}
.newBankContainer {
  background: #fafafa;
}
.connect {
  // margin-top: 50px;
  text-align: center;
  border-radius: 24px;
  // background: var(--opacity-blue-background-5, rgba(9, 84, 126, 0.05));
  padding: 48px 15px;
  .connect_imgs {
    width: 60%;
    margin: auto;
    @media only screen and (max-width: 1100px) {
      width: 100%;
    }
    img {
      margin-right: 40px;
      margin-top: 30px;
    }
  }
  h3 {
    font-weight: 800;
    font-size: 16px;
  }
  p {
    font-style: italic;
    color: #9e9e9e;
    margin-top: 30px;
  }
  .longTerm_h1 {
    color: $darkblue;
  }
  // p {
  //   margin-top: 20px;
  //   color: $black;
  //   text-align: center;
  //   font-size: 14px;
  //   font-style: normal;
  //   font-weight: 600;
  //   line-height: 24px; /* 133.333% */
  // }
}
