@import "../../../scss/var";
@import "../../../scss/common";

.whoWe {
  border-radius: 48px;
  background: var(--grey-scale-50, #fafafa);
  padding: 80px 30px 80px;
  margin-top: 30px;
  @media only screen and (max-width: 992px) {
    padding: 50px 15px 50px;
    margin-top: 0px;
  }
  @media only screen and (max-width: 567px) {
    padding: 20px 15px 20px;
  }
  .leftSide {
    border-radius: 48px;
    background: var(--colours-dark-blue, #095b7e);
    padding: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;
    @media only screen and (max-width: 992px) {
      height: inherit;
      padding: 30px 15px;
    }
    .content {
      h6 {
        color: var(--colours-700, #2dcdaf);
        font-size: 12px;
        font-style: normal;
        font-weight: 800;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.96px;
        text-transform: uppercase;
        padding-bottom: 20px;
      }
      h2 {
        color: $white;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 114.286% */
        padding-bottom: 8px;
      }
      p {
        color: var(--grey-scale-100, #e0e0e0);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
    }
  }

  .rightSide {
    border-radius: 48px;
    background: $white;
    padding: 40px;
    height: 100%;

    @media only screen and (max-width: 992px) {
      margin-top: 40px;
      height: inherit;
      padding: 30px 15px;
    }
    @media only screen and (max-width: 567px) {
      margin-top: 30px;
      padding: 15px 15px;
    }

    h2 {
      color: var(--colours-dark-blue, #095b7e);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 120% */
      padding-bottom: 20px;
      @media only screen and (max-width: 992px) {
        font-size: 18px !important;
      }
    }

    .inputs {
      margin-bottom: 20px;
      label {
        color: var(--grey-scale-700, #616161);
        font-size: 14px;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        display: block;
        margin-bottom: 5px;
      }
      input {
        border-radius: 12px;
        border: 1px solid var(--grey-scale-300, #bdbdbd);
        background: $white;
        height: 42px;
        font-weight: 500;
        width: 100%;
        padding: 0 10px;

        &::placeholder {
          color: var(--grey-scale-300, #bdbdbd);
          font-weight: 500;
        }
        &:focus {
          box-shadow: none;
        }
      }
      select {
        border-radius: 12px;
        border: 1px solid var(--grey-scale-300, #bdbdbd);
        background: $white;
        height: 42px;
        font-weight: 500;
        width: 100%;
        padding: 0 10px;

        &::placeholder {
          color: var(--grey-scale-300, #bdbdbd);
          font-weight: 500;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
    .inputsRadio {
      margin-bottom: 20px;
      label {
        color: var(--grey-scale-700, #616161);
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        display: block;
        margin-bottom: 5px;
        @media only screen and (max-width: 992px) {
          margin-bottom: 10px;
        }
      }
      .FormChecks {
        display: flex;
        gap: 20px;

        @media only screen and (max-width: 992px) {
          // flex-direction: column;
          flex-wrap: wrap;
          gap: 12px;
        }
        input {
          margin-right: 5px;
        }
      }
    }
    button {
      width: 100%;
      border-radius: 100px;
      background: var(--colours-900, #00a3ff);
      display: flex;
      height: 44px;
      padding: 0px 24px;
      justify-content: center;
      color: #fff;
      align-items: center;
      // gap: 8px;
      align-self: stretch;
      border: none;
      margin-bottom: 20px;
    }
    p {
      color: var(--grey-scale-500, #9e9e9e);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      margin-bottom: 0;
      @media only screen and (max-width: 992px) {
        br {
          display: none;
        }
        line-height: 21px;
      }
    }
  }
}

select {
  border-radius: 12px;
  border: 1px solid var(--grey-scale-300, #bdbdbd);
  background: $white;
  height: 42px;
  font-weight: 500;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 20px;

  &::placeholder {
    color: var(--grey-scale-300, #bdbdbd);
    font-weight: 500;
  }
  &:focus {
    box-shadow: none;
  }
}
.color_label {
  color: var(--colours-dark-blue, #095b7e) !important;
}
.imgWhite {
  animation: slide 3s infinite alternate;
}
@keyframes slide {
  0% {
    transform: translateX(0); /* Start position */
  }
  50% {
    transform: translateX(20px); /* Middle position */
  }
  100% {
    transform: translateX(0); /* End position */
  }
}
