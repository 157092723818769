@import "../../../scss/var";
@import "../../../scss/common";

.whoWe {
  border-radius: 48px;
  padding: 80px 30px;
  @media only screen and (max-width: 992px) {
    padding: 50px 15px 50px;
  }
  @media only screen and (max-width: 567px) {
    padding: 50px 15px 20px;
  }
  .includeSection {
    border-radius: 48px;
    background: var(--grey-scale-50, #fafafa);
    display: flex;
    padding: 30px 40px;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    justify-content: center;
    @media only screen and (max-width: 992px) {
      padding: 30px 15px;
    }

    h6 {
      color: var(--colours-900, #00a3ff);
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
    h1 {
      color: var(--colours-dark-blue, #095b7e);
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 114.286% */
      padding: 15px 0;
    }
    p {
      color: var(--grey-scale-900, #212121);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
    h4 {
      color: var(--grey-scale-700, #616161);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
      margin-bottom: 40px;
      @media screen and (max-width: 567px) {
        margin-bottom: 10px;
      }
    }
    h5 {
      color: var(--grey-scale-700, #616161);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
    }
  }

  .includeImg {
    position: relative;

    .soleImg {
      position: absolute;
      left: -20px;
      top: 100px;
      img {
        width: 40px;
        height: 40px;
        animation: grow-and-shrink 9000ms;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }
      @media only screen and (max-width: 992px) {
        display: none;
      }
    }
    .soleLineImg {
      position: absolute;
      top: 70px;
      right: -40px;
      animation: slide 3s infinite alternate;

      @media only screen and (max-width: 992px) {
        display: none;
      }
    }
    .soleBigImg {
      position: absolute;
      bottom: 100px;
      right: -40px;
      img {
        animation: grow-and-shrink 9000ms;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }
      @media only screen and (max-width: 992px) {
        display: none;
      }
    }
    .soleImgInculde {
      z-index: -1;
      left: -35px;
      img {
        width: 80px;
        height: 80px;
      }
    }
    .soleLightImg {
      position: absolute;
      right: 30px;
      top: 60px;
      animation: slide 3s infinite alternate;

      img {
        width: 100px;
        height: 4px;
      }
      @media only screen and (max-width: 992px) {
        display: none;
      }
    }
    .linerighttop {
      position: absolute;
      bottom: 60px;
      left: 30px;
      animation: slide 3s infinite alternate;

      @media only screen and (max-width: 992px) {
        display: none;
      }
    }
    img {
      width: 100%;
    }
  }
}

@keyframes grow-and-shrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slide {
  0% {
    transform: translateX(0); /* Start position */
  }
  50% {
    transform: translateX(20px); /* Middle position */
  }
  100% {
    transform: translateX(0); /* End position */
  }
}
