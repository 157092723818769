@import "../../../scss/var";

.blog_boxs {
  background: #fff;
  box-shadow: 0px 0px 9px 3px #00000029;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 25px;
  margin-top: 20px;
}

.users_addBtn {
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;

  // margin-top: 12px;
  h2 {
    font-size: 22px;
    margin: 0;
    font-weight: 600;
  }

  a {
    display: inline-block;
  }
}


.action_column {
  width: 1%;
}

.action_btn {
  display: flex;
  gap: 6px;

  a {
    display: contents;
  }

  span {
    cursor: pointer;
    padding: 4px 6px;
    border-radius: 6px;
    line-height: 0;
    border: 1px solid #ccc;
    font-size: 16px;

    &:hover {
      background-color: #f2f2f2;
    }
  }
}





.prof_page {
  padding: 0rem 0 2rem;
}

.prof_header {
  background-color: #fff;
  border-radius: 12px;
  padding: 1rem 1.5rem;
  color: #525252;
  box-shadow: 0 0 12px -6px rgba(204, 0, 1, 0.25);
  transition: 0.15s linear;
  min-height: 100px;
  display: flex;
  gap: 1.25rem;

  .prof_img {
    width: 140px;
    height: 140px;
    box-shadow: 0 0 12px -6px rgba(black, 0.25);
    border: 4px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .prof_info {
    padding: 0.8rem 0;

    h4 {
      font-size: 1.45rem;
      font-weight: 600;
      margin-bottom: 0.4rem;
      color: $primary;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
      line-height: 1;
      color: #222;
    }
  }
}

.prof_detail {

  margin-top: 2rem;
  // min-height: 400px; 
  background-color: #fff;
  border-radius: 12px;
  padding: 1rem 1.5rem;
  color: #525252;
  box-shadow: 0 0 12px -6px rgba(204, 0, 1, 0.25);
  transition: 0.15s linear;
}

.backBtn {
  margin-bottom: 1rem;

  button {
    color: #222;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 6px 10px;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    gap: 6px;

    svg {
      font-size: 1.1em;
    }
  }
}







.detail_fields {
  --spacing: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: calc(2 * var(--spacing)) 0;
  padding: 0.50rem 0;
  margin: 0 calc(-1 * var(--spacing));


  .detail_col3 {
    min-width: 140px;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0 var(--spacing);

    @include max(1366) {
      flex-basis: 50%;
      max-width: 50%;
    }

    @include max(767) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .detail_col9 {
    min-width: 140px;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0 var(--spacing);
    margin-top: 20px;

    @include max(1366) {
      flex-basis: 50%;
      max-width: 50%;
    }

    @include max(767) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  
  .detail_col4 {
    min-width: 140px;
    flex-basis: 33.33%;
    max-width: 33.33%;
    padding: 0 var(--spacing);

    @include max(1366) {
      flex-basis: 50%;
      max-width: 50%;
    }

    @include max(767) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .detail_col6 {
    min-width: 140px;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 var(--spacing);

    @include max(1366) {
      flex-basis: 50%;
      max-width: 50%;
    }

    @include max(767) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .detail_col12 {
    min-width: 100%;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0 var(--spacing);

    @include max(1366) {
      flex-basis: 100%;
      max-width: 100%;
    }

    @include max(767) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .detail_single {
    // box-shadow: 0px 0px 7px -2px rgba($primary, 0.3);
    // padding: 0rem 1rem;
    // border-radius: 4px;
    height: 100%;

    h5 {
      font-weight: 600;
      font-size: 1rem;
      text-transform: capitalize;
      color: #222;
      margin-bottom: 2px;

    }

    p {
      color: #222;
      font-size: 15px;
      margin-bottom: 0;

      span {
        margin-right: 10px;
        margin-top: 6px;
    
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // max-width: 250px;
        max-height: 315px;
        min-height: 315px;
        margin-top: 10px;
        border-radius: 4px;
      }
    }

    .detail_img {
      width: 100%;
      height: 60px;
      object-fit: cover;
      margin-top: 5px;
    }
  }
}

.titlesClass{
  font-size: 20px !important;
}