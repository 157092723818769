@import "../../../scss/var";

.formStep {
  // color: $titleColor;

  > h4 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #dee2e6;
    // padding-bottom: 15px;
    padding: 20px;
  }
  .singleField {
    position: relative;
    textarea {
      resize: none;
    }
    // input[type="file"] {
    //   display: none;
    // }

    :global(.invalid-feedback) {
      // position: absolute;
      top: calc(100% - 3px);
      font-size: 12px;
      padding: 0 6px;
      display: block;
    }
  }
  :global(.drop_area) {
    cursor: pointer;
    height: 150px;
    min-width: 150px;
    border-radius: 10px;
    border: 2px dashed #919191;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 4px;
    img {
      max-height: 100%;
      border-radius: inherit;
    }
    svg {
      display: none;
    }
  }
}
.twoFieldsWrap {
  display: flex;
  flex-wrap: wrap;
  --spacing: 15px;
  padding: 0 20px 25px;
  // overflow-x: hidden;
  gap: 1.3rem 0;
  // padding-bottom: 10px;
  margin: 0 calc(-1 * var(--spacing));
  &:not(:last-child) {
    margin-bottom: 1.8rem;
    border-bottom: 1px solid #ededed;
  }
  .singleField {
    padding: 0 var(--spacing);
    flex-basis: 100%;
    max-width: 100%;
  }
}

.imgView {
  margin-top: 10px;
}

.upImages {
  position: relative;
  input {
    height: 80px;
    opacity: 0;
    position: absolute;
    top: 30px;
    left: 10px;
    // z-index: 99;
  }
  .uploadImg {
    border: 1px solid #dee2e6;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: 80px;
    cursor: pointer;
    color: #444;
  }
}

.halfDivs {
  width: 100%;
  display: flex;
  // .singleField{
  //   width: 50%;
  //   max-width: 50%;
  // }
  @include max(767) {
    flex-direction: column;
    gap: 20px;
  }
}

.subBtn {
  background-color: #00a3ff;
  border-color: #00a3ff;
  display: flex;
  align-items: center;
  span{
    font-size: 1rem;
  }
}

.blogBox{
  background: #fff;
    box-shadow: 0px 0px 9px 3px #00000029;
    // padding: 20px;
    border-radius: 5px;
    margin-bottom: 25px;
    margin-top: 20px;
}

.pdfClass{
  position: absolute;
  top: 80px;
  left: 15px;
  svg{
    fill: #cb2929;
    width: 30px;
    height: 30px;
  }
}

.passwordEyes{
  position: relative;
  svg{
    position: absolute;
    right: 10px;
    top: 11px;
  }
}

.newClassInEditView{
  .singleField{
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 15px;
  }
}