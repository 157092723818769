@import "../../../scss/var";

.confirmationModal {
  :global(.modal-body) {
    padding: 2rem 1.5rem;
  }
  .modalContent {
    color: #222;
    display: flex;
    gap: 2.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .btnRight{
      background: #00a3ff;
      border-color: #00a3ff;
    }
    .btnLeft{
      color: #00a3ff;
      border-color: #00a3ff;
      &:hover{
        background-color: transparent;
      }
    }
  }
  .modalTitle {
    text-align: center;
    .icn {
      margin-bottom: 6px;
      font-size: 2.5rem;
      height: 4.5rem;
      width: 4.5rem;
      border-radius: 50%;
      background-color: rgba(red, 0.1);
      color: red;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    h4{
      margin-bottom: 0;
    }
  }
}
