@import "../../../scss/var";
@import "../../../scss/common";

.whoWe {
  // border-radius: 48px;
  background:$newdarkblue;

  padding: 80px 30px;
  // @media only screen and (max-width: 992px) {
  //   padding: 50px 15px 50px;
  // }
  .heading {
    h1 {
      color: $white;
      text-align: center;
      font-size: 35px;
      font-style: normal;
      font-weight: 800;
      line-height: 38px; /* 118.75% */
      padding-bottom: 0px;
      @media only screen and (max-width: 992px) {
        padding-bottom: 0;
      }
    }
    p {
      color: $white;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      @media only screen and (max-width: 992px) {
        margin-bottom: 0;
      }
    }
  }
  .box {
    border-radius: 35px;
    margin-top: 20px;
    background: var(--grey-scale-50, #fafafa);
    padding: 40px 50px 40px 50px;
    max-width: 370px;
    @media only screen and (max-width: 992px) {
      margin-bottom: 30px;
      padding: 25px 15px 40px;
    }
    .boxImg {

      img {
        margin: 20px 0;
        height: 110px;
        width: 85px;
      }
    }

    .boxText {
      h2 {
        color: $newdarkblue;
        text-align: center;
        font-size: 18.6666px;
        margin: 10px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px; /* 133.333% */
      }

      p {
        color: $black;
        margin: 20px;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 157.143% */
      }
      button {
        color: $white;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        border: none;
        border-radius: 100px;
        background: var(--colours-900, #00a3ff);
        height: 42px;
        padding: 0px 16px;
      }
    }
  }
  .dataContainer{
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }
  .weAlso {
    color: $white;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    margin-top: 40px;
    @media only screen and (max-width: 992px) {
      margin-top: 20px;
    }
  }
}
