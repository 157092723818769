@import "../../../scss/var";
@import "../../../scss//common";

.box {
  border-radius: 24px;
  background: var(--grey-scale-50, #fafafa);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 24px;
  margin: 20px 0px;
  height: 290px;
  @media only screen and (max-width: 992px) {
    margin-bottom: 30px;
    padding: 25px 15px 40px;
  }
  h6 {
    color: $primary;
  }
  p {
    color: $grey;
  }
}
