@import "../../../../scss//var";
@import "../../../../scss//common";

.homePage {
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 992px) {
    padding: 50px 0;
  }
  .leftPage {
    border-radius: 28px;
    background: linear-gradient(225deg, #00a3ff 0%, #2dcdaf 100%);
    padding: 35px 30px;
    margin-right: -158px;
    z-index: 9;
    position: relative;
    @media only screen and (max-width: 992px) {
      margin-right: 0px;
      margin-bottom: 40px;
    }
    h6 {
      color: var(--grey-scale-900, #212121);
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.96px;
      text-transform: uppercase;
      opacity: 0.8;
    }
    h1 {
      color: $white;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 46px; /* 115% */
      padding: 12px 0;
    }
    p {
      color: $white;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    .buttons {
      gap: 20px;
      display: flex;
      @media only screen and (max-width: 567px) {
        flex-direction: column;
      }
      button {
        border-radius: 100px;
        background: $white;
        display: flex;
        height: 44px;
        padding: 0px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: var(--colours-900, #00a3ff);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 157.143% */
        border: transparent;
        width: 200px;

        @media only screen and (max-width: 567px) {
          width: 100%;
        }
      }
      .actionButton {
        border-radius: 100px;
        border: 1px solid $white;
        background-color: transparent;
        color: #fff;
      }
    }
  }
  .rightPage {
    img {
      width: 100%;
    }
  }
}
.freeHiBook {
  background-color: $primary;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 80px 0px;
  gap: 40px;
  border-radius: 48px;
  h2 {
    text-align: center;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
  }
  p {
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: $grey;
    text-align: center;
  }
  .centerImg {
    border-radius: 48px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 640px;
    max-width: 1140px;
    margin: auto;
    @media (max-width: 1399px) {
      height: 600px;
      max-width: 1080px;
    }
    @media (max-width: 1199px) {
      height: 520px;
      max-width: 1000px;
    }
    @media (max-width: 1024px) {
      height: 450px;
      max-width: 800px;
    }
    @media (max-width: 991px) {
      height: 364px;
      max-width: 648px;
      border-radius: 40px;
    }
    @media (max-width: 767px) {
      height: 290px;
      border-radius: 32px;
    }
    @media (max-width: 575px) {
      height: 242px;
      max-width: 430px;
      border-radius: 28px;
    }
    @media (max-width: 480px) {
      height: 210px;
      max-width: 373px;
      border-radius: 20px;
    }
    @media (max-width: 380px) {
      height: 157px;
      max-width: 280px;
      border-radius: 18px;
    }
  }
}
.featureSoftwareContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 100px 0px;
  @media screen and (max-width: 567px) {
    margin: 34px 0px;
  }
  h2 {
    color: $primary;
    @media only screen and (max-width: 992px) {
      padding-bottom: 0;
    }
  }
  .head {
    color: $grey;
    @media only screen and (max-width: 992px) {
      margin-bottom: 0;
      padding: 0px 20px;
    }
  }
  .featureSoftwareWrapper {
    margin-top: 40px;
  }
}
.callToAction {
  background-color: $primary;
  border-radius: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 80px 150px;
  gap: 48px;
  @media only screen and (max-width: 992px) {
    margin-right: 0px;
    padding: 50px;
  }

  .row1 {
    margin-bottom: 48px;
    .row1RightContent {
      @media only screen and (max-width: 992px) {
        margin-top: 20px;
      }
    }
  }
  span {
    color: #ffffff;
  }
  h2 {
    color: #ffffff;
    margin: 0px;
  }
  p {
    color: #ffffff;
    margin: 0px;
  }
  .small {
    color: $grey;
  }
  .row3 {
    margin-top: 48px;
  }
  .buttons {
    gap: 20px;
    display: flex;
    justify-content: flex-end;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
      margin-top: 20px;
    }
    button {
      border-radius: 100px;
      background: $secondary;
      display: flex;
      height: 44px;
      padding: 0px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: #ffffff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px; /* 157.143% */
      border: transparent;
    }
    .actionButton {
      color: $secondary;
      border-radius: 10rem;
      border-color: $secondary;
      background-color: transparent;
      border: 1px solid $secondary;
    }
  }
  img {
    width: 100%;
    @media only screen and (max-width: 992px) {
      width: 100%;
      padding: 0px;
    }
  }
}

.circleblue {
  position: absolute;
  right: -49px;
  bottom: 110px;
  img {
    animation: grow-and-shrink 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.circlelight {
  position: absolute;
  left: -50px;
  top: 50%;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
  @media screen and (max-width: 1400px) {
    top: 42%;
  }
}

.topLight {
  top: 28%;
  @media screen and (max-width: 1400px) {
    top: 19%;
  }
}
.greenDotAndLine {
  position: absolute;
  right: 35px;
  animation: slide 3s infinite alternate;

  top: 169px;
  @media only screen and (max-width: 1100px) {
    display: none;
  }
}
.lineWhite {
  position: absolute;
  top: 36%;
  left: 50%;
  animation: slide 3s infinite alternate;

  z-index: 9;
  @media only screen and (max-width: 1100px) {
    display: none;
  }
}

.linegrayss {
  position: absolute;
  bottom: -60px;
  left: -100px;
  right: 0;
  animation: slide 3s infinite alternate;

  text-align: center;
  @media only screen and (max-width: 1100px) {
    display: none;
  }
}
.linegrayssTop {
  position: absolute;
  top: -60px;
  left: -100px;
  right: 0;
  animation: slide 3s infinite alternate;

  text-align: center;
  @media only screen and (max-width: 1100px) {
    display: none;
  }
}

.mainPage {
  position: relative !important;
}

.grow {
  img {
    animation: grows 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}
.shrink {
  img {
    animation: grow-and-shrink 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}

@keyframes slide {
  0% {
    transform: translateX(0); /* Start position */
  }
  50% {
    transform: translateX(20px); /* Middle position */
  }
  100% {
    transform: translateX(0); /* End position */
  }
}

@keyframes grows {
  0% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}
@keyframes grow-and-shrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
