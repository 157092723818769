@import "../../../scss/var";
@import "../../../scss/common";

.whychooseus {
  padding: 115px 80px 0 80px;
  @media only screen and (max-width: 567px) {
    padding: 15px;
  }
  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      border-radius: 50px;
      height: 479px;
      width: 444px;
      object-fit: cover;
      @media only screen and (max-width: 567px) {
        width: 100%;
        height: 100%;
      }
    }
  }
  .textContainer{
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    height: 100%;
  }
  button {
    border-radius: 100px;
        border: 1px solid $white;
        background: #11afe6;
        color: #fff;
        font-size: 16px;
        font-weight: 800;
        min-width: 200px;
        width: auto;
        height: 54px;
        padding: 0px 24px;
    @media only screen and (max-width: 567px) {
      width: 100%;
    }
  }
  h1 {
    font-weight: 800;
    font-size: 40px;
    color: $newdarkblue;
    .gradientText {
      padding-left: 15px;
      background: linear-gradient(to right, #1fbeca, #05aee9);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  ul {
    padding-top: 20px;
    @media only screen and (max-width: 567px) {
      padding-top: 0;
    }
    li {
      font-size: 16px;
      font-family: Manrope,sans-serif;
      line-height: 23px;
      padding-top: 20px;
      font-weight: 500;
      color: $black;
    }
  }
}
