@import "../../../scss/var";
@import "../../../scss/common";


.logo {
    padding: 0 1rem 1rem;
    a {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    span {
      font-weight: 600;
      font-size: 1rem;
      color: #ffffffe3;
    }
    img {
      max-height: 30px;
      width: 105px;
    }
  }
  .sidebarWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .sidebarList {
    padding: 1.4rem 0.5rem;
    ul {
      margin-top: 1rem;
      padding: 0;
      font-size: 14px;
      a {
        display: block;
        cursor: pointer;
        position: relative;
        transition: 0.15s ease-in-out;
        margin-bottom: 1rem;
        transition: 0.2s linear;
        &:global(.active) {
          border-color: #2a2a2a;
          color: $secondary;
          li {
            color: inherit;
          }
        }
      }
      li {
        color: #ffffffb4;
        font-weight: 400;
        cursor: pointer;
        padding: 4px 1rem;
        display: flex;
        gap: 14px;
        align-items: center;
        svg {
          opacity: 0.9;
          font-size: 1.2em;
        }
      }
    }
  }
  