@import "../../../scss/var";
.homePage {
  padding: 10px 0 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 992px) {
    padding: 10px 0 50px;
  }
  .leftPage {
    border-radius: 48px;
    background: linear-gradient(225deg, #00a3ff 0%, #2dcdaf 100%);
    padding: 58px 43px;
    padding-bottom: 20px;
    margin-right: -181px;
    z-index: 9;
    position: relative;
    left: 10%;
    width: 630px;
    @media only screen and (max-width: 992px) {
      margin-right: 0px;
      margin-bottom: 40px;
      left: 0;
    }
    h6 {
      color: #ffffff;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.5px;
      text-transform: capitalize;
      margin-bottom: 25px;
      @media only screen and (max-width: 992px) {
        line-height: 1.37em;
      }
    }
    h1 {
      color: $black;
      font-size: 50px;
      font-style: normal;
      font-weight: 1000;
      line-height: 46px; /* 115% */
    }
    .provide_h1 {
      color: $white;
      font-size: 48px;
      font-style: normal;
      font-weight: 1000;
      line-height: 46px; /* 115% */
      // padding: 12px 0;
    }
    p {
      padding: 15px 0px;
      color: #3d3d3e;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
    .buttons {
      gap: 20px;
      display: flex;
      @media only screen and (max-width: 567px) {
        flex-direction: column;
      }
      button {
        border-radius: 100px;
        background: $white;
        display: flex;
        height: 50px;
        padding: 0px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: var(--colours-900, #00a3ff);
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.37em;
        border: transparent;
        @media only screen and (max-width: 567px) {
          width: 100%;
        }
      }
      .actionButton {
        border-radius: 100px;
        border: 1px solid $white;
        background-color: transparent;
        color: #fff;
      }
    }
  }
  .rightPage {
    position: relative;

    img {
      width: 92%;
      border-radius: 48px;
      height: 540px;
      object-fit: cover;
      @media only screen and (max-width: 992px) {
        width: 100%;
      }
      @media only screen and (max-width: 767px) {
        height: unset;
      }
    }

    &::before {
      position: absolute;
      z-index: -1;
      /* top: 0; */
      left: -36px;
      content: "";
      background-color: rgb(241, 241, 241);
      width: 91%;
      height: 40%;
      border-radius: 48px;
      bottom: -60px;
      @media only screen and (max-width: 992px) {
        left: -5px;
      }
    }
  }
}

.WhyChoose {
  border-radius: 48px;
  overflow: hidden;
  @media only screen and (max-width: 992px) {
    padding: 30px 0;
  }
  .slickSlider {
    h2 {
      text-align: center;
      background: -webkit-linear-gradient(225deg, #00a3ff 0%, #2dcdaf 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
      font-size: 44px;
      font-style: normal;
      font-weight: 900;
      @media only screen and (max-width: 992px) {
        margin-bottom: 20px;
      }
    }
    p {
      color: $darkblue;
      text-align: center;
      margin-bottom: 60px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      @media only screen and (max-width: 567px) {
        margin-bottom: 10px;
      }
    }
  }
  .chosseBoxImg {
    img {
      margin-bottom: 25px;
      margin: auto;
    }
  }

  .chosseBox {
    // border-radius: 24px;
    // background: $white;
    padding: 30px 25px;
    // width: 95% !important;
    @media only screen and (max-width: 992px) {
      width: 100% !important;
    }
    // @media screen and (min-width: 1360px) {
    //   height: 445px;
    // }
    .chooseText {
      margin-top: 20px;
      text-align: center;
      @media only screen and (max-width: 992px) {
        margin-top: 40px;
      }
      h2 {
        color: $darkblue;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 1.37em;
      }
      p {
        color: $black;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.37em;
        margin-top: 20px;
      }
    }
  }
}

.slickSliderMAin {
  margin-left: 80px;
  @media only screen and (max-width: 992px) {
    width: 95%;
    margin: 0 auto;
  }
  .recieveBtn {
    height: 54px;
    padding: 0px 30px;
    font-weight: 800;
    font-size: 14px;
    @media only screen and (max-width: 992px) {
      margin-top: 40px;
    }
  }
}
.connect {
  // margin-top: 50px;
  text-align: center;
  border-radius: 24px;
  // background: var(--opacity-blue-background-5, rgba(9, 84, 126, 0.05));
  padding: 48px 15px;
  .connect_imgs {
    width: 60%;
    margin: auto;
    @media only screen and (max-width: 1100px) {
      width: 100%;
    }
    img {
      margin-right: 40px;
      margin-top: 30px;
    }
  }
  h3 {
    font-weight: 800;
    font-size: 16px;
  }
  p {
    font-style: italic;
    color: #9e9e9e;
    margin-top: 30px;
  }
  .longTerm_h1 {
    color: $darkblue;
  }
  // p {
  //   margin-top: 20px;
  //   color: $black;
  //   text-align: center;
  //   font-size: 14px;
  //   font-style: normal;
  //   font-weight: 600;
  //   line-height: 24px; /* 133.333% */
  // }
}

.longTerm {
  // margin-top: 50px;
  text-align: center;
  border-radius: 24px;
  // background: var(--opacity-blue-background-5, rgba(9, 84, 126, 0.05));
  padding: 48px 15px 0px;
  .line {
    margin: 10px 0;
  }
  h1 {
    font-weight: 800;
  }
  .longTerm_h1 {
    background: -webkit-linear-gradient(225deg, #00a3ff 0%, #2dcdaf 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 900;
    font-size: 44px;
  }
  p {
    margin-top: 20px;
    color: $black;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
  }
}
.MapSection {
  padding: 80px 0;
  .officeHeading {
    text-align: center;
    h2 {
      color: var(--colours-dark-blue, #095b7e);
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px; /* 118.75% */
    }
    p {
      color: var(--grey-scale-700, #616161);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
  }
  .maps {
    iframe {
      border-radius: 16px;
    }
  }
}
.officeText {
  border-radius: 48px;
  background: var(--grey-scale-50, #fafafa);
  padding: 40px 30px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: 992px) {
    margin-top: 40px;
  }
  .innerText {
    h3 {
      color: var(--colours-dark-blue, #095b7e);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 133.333% */
      padding-top: 5px;
    }
    p {
      color: var(--grey-scale-700, #616161);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 5px;
      line-height: 22px; /* 157.143% */
    }
  }
}

.circleblue {
  position: absolute;
  right: 8px;
  bottom: 110px;

  img {
    animation: grow-and-shrink 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.circlelight {
  position: absolute;
  left: -10px;
  top: 52%;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
  @media screen and (max-width: 1400px) {
    top: 50%;
  }
}
.grow {
  img {
    animation: grows 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}
.shrink {
  img {
    animation: grow-and-shrink 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}

.topLight {
  top: 25%;
}
.lineWhite {
  position: absolute;
  top: 31%;
  left: 51%;
  z-index: 9;
  animation: slide 3s infinite alternate;
  @media only screen and (max-width: 1100px) {
    display: none;
  }
}

.linegrayss {
  position: absolute;
  bottom: -60px;
  left: -180px;
  right: 0;
  animation: slide 3s infinite alternate;

  text-align: center;
  @media only screen and (max-width: 1100px) {
    display: none;
  }
}

.linegrayssTop {
  position: absolute;
  top: -47px;
  left: 69px;
  right: 0;
  animation: slide 3s infinite alternate;
  text-align: center;
  @media only screen and (max-width: 1100px) {
    display: none;
  }
}

.banRightLine {
  text-align: right;
  position: absolute;
  right: -38px;
  top: 70px;
  z-index: 1;
  animation: slide 3s infinite alternate;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.mainPage {
  position: relative !important;
}

@keyframes slide {
  0% {
    transform: translateX(0); /* Start position */
  }
  50% {
    transform: translateX(20px); /* Middle position */
  }
  100% {
    transform: translateX(0); /* End position */
  }
}

@keyframes grows {
  0% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}
@keyframes grow-and-shrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
