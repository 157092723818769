@import "../../../scss/var";
@import "../../../scss/common";

.whoWe {
  border-radius: 48px;
  background: $white;
  padding: 0px 30px 80px;
  @media only screen and (max-width: 992px) {
    padding-bottom: 20px;
  }
  .heading {
    h2 {
      color: var(--colours-dark-blue, #095b7e);

      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px; /* 118.75% */
      padding-bottom: 10px;
    }
    h1 {
      color: var(--colours-dark-blue, #095b7e);

      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px; /* 118.75% */
      padding-bottom: 10px;
    }
    p {
      color: var(--grey-scale-700, #616161);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
  }
  .box {
    border-radius: 24px;
    background: var(--grey-scale-50, #fafafa);
    // padding: 25px 25px 40px;
    @media only screen and (max-width: 992px) {
      margin-bottom: 40px;
    }

    .boxText {
      padding: 0 20px 20px;
      h6 {
        color: var(--colours-900, #00a3ff);
        font-size: 12px;
        font-style: normal;
        text-align: left;
        font-weight: 600;
        line-height: 18px; /* 150% */
      }
      h2 {
        color: var(--colours-dark-blue, #095b7e);
        text-align: left;
        /* H7 */
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 133.333% */
      }

      p {
        color: var(--grey-scale-700, #616161);
        text-align: left;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }
    }
  }
}

.accordions {
  padding: 0 !important;
  .acodion_main {
    .acodion_items {
      background-color: #fafafa;
      margin-bottom: 15px;
      border-radius: 24px;
      border: none;
      padding: 0 15px;

      button {
        background-color: transparent;
        border: none;
        padding: 0;
        box-shadow: none;
        color: var(--colours-dark-blue, #095b7e);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 133.333% */
        padding: 10px 24px;
        @media only screen and (max-width: 992px) {
          padding: 16px 0px;
        }
        &::after {
          background-color: $white;
          width: 40px !important;
          height: 40px !important;
          display: none;
          border-radius: 100%;
          background-position: center;
        }
      }
      .acodion_body {
        border: none;
        padding: 20px 0;
        color: var(--grey-scale-700, #616161);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        padding: 16px 24px;
        @media only screen and (max-width: 992px) {
          padding: 16px 0px;
        }
      }
    }
  }
}



/* faqs.module.scss */
.customAccordionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  button{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.plusMinusIcon {
  font-size: 24px;
  margin-right: 0px;
  transition: transform 0.2s;
  background-color: $white;
  width: 40px !important;
  min-width: 40px !important;
  height: 40px !important;
  display: block;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 992px) {
    margin-left: 20px;
  }
}

/* Change the plus icon to a minus icon when the accordion is open */
.customAccordionHeader.accordion-button-pressed .plusMinusIcon {
  transform: rotate(45deg);
}