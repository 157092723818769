@import "../../../scss/var";

.blog_boxs {
  background: #fff;
  box-shadow: 0px 0px 9px 3px #00000029;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 25px;
  margin-top: 20px;
}
.users_addBtn {
  text-align: right;
}

.action_column {
  width: 1%;
}
.action_btn {
  display: flex;
  gap: 6px;
  a {
    display: contents;
  }
  span {
    cursor: pointer;
    padding: 4px 6px;
    border-radius: 6px;
    line-height: 0;
    border: 1px solid #ccc;
    font-size: 16px;
    &:hover {
      background-color: #f2f2f2;
    }
  }
}

.users_addBtn {
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-top: 12px;
  h2{
    font-size: 22px;
    margin: 0;
    font-weight: 600;
  }
  a{
    display: inline-block;
  }
}