@import "../../../scss/var";
@import "../../../scss/common";

.topBar {
  box-shadow: 0px 4px 10px -8px rgb(0 0 0 / 35%);
  height: 46px;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 9;
  padding: 4px 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topBar_search {
  input {
    // border-color: $borderColor;
    min-height: 0;
    max-height: 30px;
    font-size: 14px;
  }
  button {
    border: none;
    font-size: 1.45rem;
    padding: 4px;
    background: none;
    line-height: 1;
  }
}
.topBar_checkTime {
//   color: $titleColor;
  font-size: 14px;
  font-weight: 600;
}
.login_user {
  display: inline-flex;
  align-items: center;
  gap: 10px;
//   color: $titleColor;
  font-size: 14px;
  img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }

}
