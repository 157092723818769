@import "../../scss/var";
@import "../../scss/common";
.infoSection {

    padding: 20px;
  }
  
  .textContainer {
    max-width: 700px;
  }
  
  .subheading {
    font-size: 18.6666px;
    color: #2E4369;
    margin-bottom: 26px;
  }
  .contentSec{
    align-items: center;
    padding: 0 55px 0 55px;
    @media only screen and (max-width: 992px) {
      padding: 0 ;
    }
  }
  .heading {
    font-size: 40px;
    font-weight: 900;
    color: $newdarkblue;
    margin: 0 0 50px 0;
    @media only screen and (max-width: 992px) {
      margin: 0 0 20px 0 ;
    }
  }
  
  .description {
    font-size: 16px;
    color: $black;
    // margin-bottom: 20px;
    font-weight: 400;
    margin: 40px 0 28px 0 ;
    @media only screen and (max-width: 992px) {
        margin: 20px 0 20px 0 ;
      }
  }
  .description2 {
    font-size: 16px;
    color: $black;
    // margin-bottom: 20px;
    font-weight: 400;
    margin: 0 0 40px 0;
    @media only screen and (max-width: 992px) {
        margin: 20px 0 20px 0 ;
      }
  }
  
  .button {
    background-color: $secondary;
    color: #fff;
    border: none;
    border-radius: 50px;
    font-weight: 800;
    margin: 20px 0 20px 0 ;
    padding: 20px 60px;
    cursor: pointer;
    font-size: 15.57px;
    @media only screen and (max-width: 992px) {
        width: 60%;
        padding: 15px 40px;
      }
  }
  .image {
    width: auto;
    height: auto;
    
  }
  