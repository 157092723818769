@import "../../../scss/var";
@import "../../../scss/common";

.whoWe {
  border-radius: 48px;
  // background: var(--colours-dark-blue, #095b7e);
  padding: 0px 30px 80px;
  @media only screen and (max-width: 992px) {
    padding: 50px 15px 50px;
  }
  @media only screen and (max-width: 567px) {
    padding: 20px 15px 20px;
  }
  .heading {
    h1 {
      background: -webkit-linear-gradient(225deg, #00a3ff 0%, #2dcdaf 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 38px; /* 118.75% */
      padding-bottom: 40px;
      @media only screen and (max-width: 992px) {
        padding-bottom: 0;
      }
    }
    p {
      color: $darkblue;
      text-align: center;
      font-size: 18px;
      margin-bottom: 0;
      width: 80%;
      font-style: normal;
      font-weight: 800;
      line-height: 24px; /* 150% */
      @media only screen and (max-width: 992px) {
        margin-bottom: 0;
      }
    }
  }
  .box {
    border-radius: 24px;
    background: $darkblue;
    padding: 25px 25px 40px;
    @media only screen and (max-width: 992px) {
      margin-bottom: 30px;
      padding: 25px 15px 40px;
    }
    .boxImg {
      img {
        margin: 20px 0;
      }
    }

    .boxText {
      h2 {
        color: $white;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 24px; /* 133.333% */
        margin-bottom: 30px;
      }
      h3 {
        color: $white;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        margin-bottom: 30px;
      }
      p {
        color: $white;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.37em;
        margin: auto;
        margin-bottom: 20px;
      }
      button {
        color: var(--colours-900, #00a3ff);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        border: none;
        border-radius: 100px;
        background: #fff;
        height: 54px;
        padding: 0px 40px;
      }
    }
  }
  .weAlso {
    color: $darkblue;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    margin-top: 60px;
    padding: 0 100px;
    @media only screen and (max-width: 992px) {
      margin-top: 20px;
      padding: 0 0px;
    }
  }
}
// PricingCard.scss
.pricingContainer{
  display: flex;
  justify-content: space-evenly;
  align-items: start;
  @media only screen and (max-width: 992px) {
   flex-direction: column;
   align-items: center;
   gap: 20px;
  }

  .pricingCard {
    background-color: #00547d;
    color: white;
    padding: 50px 60px 50px 60px;
    border-radius: 30px;
    text-align: center;
    max-width: 400px;
    width: 100%;
    text-align: left;
  
    &__title {
      font-size: 26px;
      font-weight: 800;
    }
  
    &__plan {
      font-size: 26px;
      color: #00aaff;
      margin-top: 5px;
      font-weight: 800;
      
    }
  
    &__price {
      margin: 30px 0;   
  
      .priceOriginal {
        text-decoration: line-through;
        color: $white;
        font-size: 36px;
        margin-right: 10px;
        text-decoration: line-through;
        font-weight: 500;
      }
      
      .priceDiscounted {
        font-weight: bold;
        font-size: 46px;
        color: $white;
        margin-right: 10px;
      }
  
      .perMonth {
        display: block;
        font-size: 16px;
        font-weight: 500;
        color: $white;
      }
    }
  
    .pricingCard__description {
      font-size: 14.64px;
      color: $white;
      font-weight: 500;
      margin-bottom: 15px;
    }
  
    .pricingCard__features {
      padding: 0;
     
      margin-bottom: 20px;
  
      li {
        margin: 5px 0;
        font-size: 15.97px;
        color: $white;
        font-weight: 500;
       
      }
    }
   &__buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0 20px 0;
   }
    &__button {
      background-color: white;
      color: #11AFE6;
      border: none;
      padding: 16px 50px;
      border-radius: 50px;  
      font-weight: bold;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #e0e0e0;
      }
    }
  }

}
