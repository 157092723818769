@import "../../../../scss//var";
@import "../../../../scss//common";
@import "../../../../scss/App.scss";

.guidesAndVideosPage {
  background: var(--grey-scale-50, #fafafa);
  padding: 140px 194px;
  border-radius: 48px;
  text-align: center;
  margin: 100px 0px;
  position: relative;
  @media only screen and (max-width: 992px) {
    padding: 30px;
  }
  .check {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  span {
    color: $secondary;
    font-style: normal;
    font-weight: 800;
    line-height: 16px;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    margin: 0px;
  }
  h1 {
    color: $primary;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
    margin: 0px;
  }
  p {
    color: var(--grey-scale-700, #616161);
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 0px;
  }

  input {
    padding: 10px 12px;
    color: $grey;
    border-radius: 12px;
    border: 1px solid var(--grey-scale-300, #bdbdbd);
    background: $white;
    outline: none;
  }

  .buttons {
    display: flex;
    gap: 8px;
    justify-content: center;
    max-width: 300px;
    button {
      width: 205px;
    }
    margin: auto;
    @media only screen and (max-width: 425px) {
      flex-direction: column;
      width: 100%;
    }
  }
  .input_wrapper {
    position: relative;
    max-width: 300px;
    margin: 0 auto;
    input {
      width: 100%;
      padding-right: 1.75rem;
    }
    img {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }
}
.whyChooseAccount {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  @media only screen and (max-width: 992px) {
    padding: 0px 15px;
  }
  .cardAndIcon {
    display: flex;
  }
  h2 {
    color: $primary;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
  }
  p {
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: $grey;
  }
  .accountButtons {
    background: var(--grey-scale-50, #fafafa);
    padding: 40px 0px;
    border-radius: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    text-align: center;

    .buttonGap {
      display: flex;
      gap: 4px;
      justify-content: center;
      @media only screen and (max-width: 992px) {
        flex-direction: column;
        margin-top: 10px;
        padding: 20px;
      }

      .buttoncolor {
        background-color: $primary;
      }
    }
  }
}
.newadd {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  @media only screen and (max-width: 992px) {
    padding: 0px 15px;
  }
  .cardAndIcon {
    display: flex;
  }
  h2 {
    color: $primary;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
  }
  p {
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: $grey;
  }
  .accountButtons {
    background: var(--grey-scale-50, #fafafa);
    padding: 40px 0px;
    border-radius: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    text-align: center;
    div {
      margin: 0px !important;
    }
    .buttonGap {
      display: flex;
      gap: 4px;
      justify-content: center;
      @media only screen and (max-width: 992px) {
        flex-direction: column;
        margin-top: 10px;
        padding: 20px;
      }

      .buttoncolor {
        background-color: $primary;
      }
    }
  }
}
.videoContainer {
  overflow: hidden;
  margin-bottom: 100px;

  .slickSlider {
    h2 {
      color: $primary;
      font-style: normal;
      font-weight: 600;
      line-height: 38px; /* 118.75% */
      @media only screen and (max-width: 992px) {
        margin-bottom: 20px;
      }
    }
    p {
      color: var(--grey-scale-700, #616161);
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }

  .chosseBox {
    border-radius: 24px;
    background: var(--grey-scale-50, #fafafa);
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95% !important;
    gap: 24px;
    @media only screen and (max-width: 992px) {
      width: 100% !important;
    }

    .chosseBoxImg {
      img {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px auto 15px auto;
      }
    }
    .chooseText {
      @media only screen and (max-width: 992px) {
        margin-top: 40px;
      }
      h6 {
        color: var(--grey-scale-900, #212121);
        font-style: normal;
        line-height: 24px;
        text-align: center; /* 120% */
      }
      p {
        color: var(--grey-scale-700, #616161);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        margin-top: 12px;
        text-align: center;
      }
    }
    .buttoncheck {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
  }
}
.circlelight {
  position: absolute;
  left: -60px;
  top: 35%;
  @media only screen and (max-width: 1440px) {
    position: absolute;
    left: -60px;
    top: 35%;
  }

  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.topLight {
  top: 10%;
  @media only screen and (max-width: 1440px) {
    top: 10%;
  }
  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.circleblue {
  position: absolute;
  right: -50px;
  top: 60%;
  img {
    animation: grow-and-shrink 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  @media only screen and (max-width: 1440px) {
    position: absolute;
    right: -50px;
    top: 60%;
  }
  @media only screen and (max-width: 1200px) {
    display: none;
  }
}
.greenLine {
  position: absolute;
  left: 20%;
  animation: slide 3s infinite alternate;

  top: 15%;
  @media only screen and (max-width: 1440px) {
    position: absolute;
    left: 20%;
    top: 15%;
  }
  @media only screen and (max-width: 1200px) {
    display: none;
  }
}
.lineAndDot {
  position: absolute;
  right: -50px;
  animation: slide 3s infinite alternate;

  top: 10%;
  @media only screen and (max-width: 1440px) {
    position: absolute;
    right: -50px;
    top: 10%;
  }
  @media only screen and (max-width: 1200px) {
    display: none;
  }
}
.GrayLine {
  position: absolute;
  right: 15%;
  top: 35%;
  @media only screen and (max-width: 1440px) {
    position: absolute;
    right: 15%;
    top: 35%;
  }
  @media only screen and (max-width: 1200px) {
    display: none;
  }
}
.lightBlueLine {
  position: absolute;
  left: -30px;
  bottom: 8%;
  animation: slide 3s infinite alternate;

  @media only screen and (max-width: 1440px) {
    position: absolute;
    left: -30px;
    bottom: 8%;
  }
  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.playButton {
  display: flex;
  align-items: center;
  gap: 4px;
  outline: none;
  border: 1px solid transparent;
  padding: 0px 16px;
  height: 36px;
  border-color: transparent;
  color: #ffffff;
  border-radius: 10rem;
  background-color: #00a3ff;
}

iframe {
  height: 400px;
  object-fit: cover;
}
.closeIcon {
  position: absolute;
  border: none;
  right: 10px;
  top: 10px;
  outline: none;
  background-color: #00a3ff;
  color: #fff;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
  z-index: 999;
}

.centerLoader {
  text-align: center;
  span {
    width: 30px !important;
    height: 30px !important;
    border: var(--bs-spinner-border-width) solid #0dcaf0;
    border-right-color: transparent;
  }
}
.emtytext {
  text-align: center;
  font-size: 20px;
  margin: 30px 0px;
}

.grow {
  img {
    animation: grows 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}
.shrink {
  img {
    animation: grow-and-shrink 9000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}

@keyframes slide {
  0% {
    transform: translateX(0); /* Start position */
  }
  50% {
    transform: translateX(20px); /* Middle position */
  }
  100% {
    transform: translateX(0); /* End position */
  }
}

@keyframes grows {
  0% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}
@keyframes grow-and-shrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
