@import "../../../scss/var";
// @mixin cardStyle {
//   background-color: #fff;
//   padding: 1rem 1.5rem;
//   border-radius: 8px;
//   color: $titleColor;
//   box-shadow: 0 0 18px -6px rgba(black, 0.25);
//   cursor: pointer;
//   transition: 0.15s linear;
//   &:hover {
//     box-shadow: 0px 0px 26px -6px rgba(black, 0.3);
//   }
// }
.dash_page {
  padding: 1rem 0;
}
.dash_card {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  min-height: 120px;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  min-height: 120px;
  background-color: #fff;
  border-radius: 12px;
  padding: 1rem 1.5rem;
  color: #525252;
  box-shadow: 0 0 18px -6px rgba(255, 114, 92, 0.25);
  transition: 0.15s linear;
//   @include box;
  .dash_cardImg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: rgba($secondary, 0.1);
    svg {
      font-size: 1.5em;
      color: $secondary;
    }
  }
  h5 {
    font-size: 1rem;
    font-weight: 400;
    // color: $textColor;
  }
  h3 {
    font-size: 2.6rem;
    font-weight: 700;
  }
}
.dash_cardLg {
  min-height: 550px;
//   @include box;
}
