@import "../../../scss/var";
@import "../../../scss/common";

.whoWe {
  border-radius: 48px;
  background: $white;
  padding: 80px 30px;
  @media only screen and (max-width: 992px) {
    padding: 50px 15px 50px;
  }
  @media only screen and (max-width: 567px) {
    padding: 50px 15px 20px;
  }
  .heading {
    h2 {
      color: var(--colours-dark-blue, #09547e);

      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px; /* 118.75% */
      padding-bottom: 10px;
      @media only screen and (max-width: 992px) {
        padding-bottom: 0;
      }
    }
    p {
      color: var(--colours-dark-blue, #09547e);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }
  }
  .box {
    border-radius: 24px;
    background: var(--grey-scale-50, #fafafa);
    padding: 25px 25px 40px;

    // height: 287px;
    // height: max-content;
    // @media only screen and (max-width: 1400px) {
    //   height: 494px;
    // }
    // @media only screen and (max-width: 1200px) {
    //   height: 606px;
    // }
    @media only screen and (max-width: 1100px) {
      // margin-bottom: 40px;
      padding-bottom: 20px;
      height: fit-content !important;
    }
    @media only screen and (max-width: 567px) {
      height: fit-content !important;
      margin-bottom: 22px;
    }
    .boxImg {
      display: flex;
      align-items: flex-start;
      margin-bottom: 8px;

      img {
        margin: 0px 15px 0 0;
      }
      .userText {
        h3 {
          color: var(--colours-dark-blue, #09547e);
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 150% */
          text-align: left;
          margin: 0px;
          // margin-bottom: 7px;
        }
        p {
          color: var(--colours-dark-blue, #09547e);
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px; /* 157.143% */
          text-align: left;
          margin-bottom: 0px;
        }
      }
    }

    .boxText {
      text-align: left;

      p {
        padding-top: 10px;
        color: $black;
        text-align: left;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        margin-bottom: 0;
      }
    }
  }
}

.whoWeWhiteBg {
  background: var(--colours-dark-blue, #095b7e);
  margin-bottom: 80px;
  @media (max-width: 567px) {
    margin-bottom: 40px;
  }
  .heading {
    h2 {
      color: $white;
    }
    p {
      color: var(--grey-scale-500, #9e9e9e);
    }
  }

  .box {
    background: var(--opacity-white-background-5, rgba(255, 255, 255, 0.05));

    .boxImg {
      .userText {
        h3 {
          color: $white;
        }
        p {
          color: var(--colours-900, #00a3ff);
        }
      }
    }

    .boxText {
      p {
        color: $white;
      }
    }
  }
}
