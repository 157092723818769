@import "../../../scss/var";
@import "../../../scss/common";

.whoWe {
  padding: 20px 120px 60px;
  background: #f1f1f1;
  @media only screen and (max-width: 992px) {
    padding-bottom: 20px;
    padding: 20px 15px;
  }
  .heading {
    h2 {
      color: $darkblue;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.84em;
      padding-bottom: 20px;
      margin: 0px;
    }
  }
  .box {
    border-radius: 24px;
    background: $white;
    width: 367px;
    height: 470px;
    @media only screen and (max-width: 992px) {
      width: 100%;
      height: 500px;
    }
    @media only screen and (max-width: 567px) {
      height: 500px;
    }
    .boxImg {
      img {
        margin: 0px 0 20px;
        width: 100%;
        height: 244px;
        object-fit: cover;
        max-width: 367px;
        border-radius: 10px;
        @media only screen and (max-width: 992px) {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    .boxText {
      padding: 0 20px 20px;
      h3 {
        color: $darkblue;
        font-size: 18px;
        font-style: normal;
        text-align: left;
        font-weight: 800;
        line-height: 1.35em;
      }
      p {
        color: $black;
        text-align: left;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.39em;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        margin-top: 20px;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .weAlso {
    color: $white;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    margin-top: 40px;
  }
  .accounting_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 80px;
    place-items: center;
    @media (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
      row-gap: 50px;
    }
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      row-gap: 40px;
    }
  }
}

a {
  color: inherit;
  text-decoration: inherit;
}
