@import "../../../scss/var";
@import "../../../scss/common";

.whoWe {
  border-radius: 48px;
  // background: $white;
  padding: 80px 30px;
  @media only screen and (max-width: 992px) {
    padding-bottom: 20px;
    padding-top: 50px;
  }
  .heading {
    h2 {
      color: var(--colours-dark-blue, #09547e);

      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px; /* 118.75% */
      padding-bottom: 10px;
    }
    p {
      color: var(--colours-dark-blue, #09547e);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
  }
  .box {
    border-radius: 24px;
    background: var(--grey-scale-50, #fafafa);
    margin-bottom: 16px;
    // padding: 25px 25px 40px;
    @media only screen and (max-width: 992px) {
      margin-bottom: 40px;
    }
    .boxImg {
      img {
        margin: 0px 0 20px;
        width: 100%;
        height: 160px;
        object-fit: cover;
        max-width: 310px;
        border-radius: 20px 20px 0px 0px;
        transition: all 200ms ease-in;
        transform: scale(1);
      }
      :hover {
        transform: scale(0.91);
      }
    }

    .boxText {
      padding: 0 20px 20px;
      h6 {
        color: var(--colours-900, #00a3ff);
        font-size: 12px;
        font-style: normal;
        text-align: left;
        font-weight: 600;
        line-height: 18px; /* 150% */
      }
      h2 {
        color: $darkblue;
        text-align: left;
        /* H7 */
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        // line-height: 24px; /* 133.333% */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 57px;
        @media only screen and (max-width: 992px) {
          height: auto;
        }
      }

      p {
        color: $black;
        text-align: left;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        // line-height: 22px; /* 157.143% */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      button {
        color: #09547e;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        border: none;
        line-height: 18px; /* 150% */
        background-color: transparent;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin-bottom: 10px;
        margin-top: 10px;
        span {
          color: #2dcdaf;
          font-size: 1.5rem;
        }
      }
    }
  }
  .weAlso {
    color: $white;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    margin-top: 40px;
  }
}

a {
  color: inherit;
  text-decoration: inherit;
}
