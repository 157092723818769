@import "../../../scss/var";

.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .footerTopContent {
    display: flex;
    // width: 1440px;
    padding: 40px 0px 100px 0px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    text-align: center;
    @media only screen and (max-width: 992px) {
      padding: 40px 0px 40px 0px;
    }
    @media only screen and (max-width: 992px) {
      padding: 8px 0px 40px 0px;
    }
    .footerFirstContent {
      h4 {
        color: $primary;
        font-weight: 600;
      }
      p {
        color: #616161;
        font-weight: 500;
      }
    }
    .footerSecondContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      @media only screen and (max-width: 992px) {
        flex-direction: column;
      }
    }
  }
  .footerThirdContent {
    .footerMainContent {
      display: flex;
      // width: 1140px;

      gap: 24px;
      @media only screen and (max-width: 992px) {
        flex-direction: column;
      }
      .footerLeftContent {
        flex: 3;
        padding: 40px;
        box-shadow: 0px 20px 25px 1px rgba(20, 34, 108, 0.1);
        justify-content: space-between;
        display: flex;
        border-radius: 24px;
        @media only screen and (max-width: 992px) {
          padding: 30px 20px;
          flex-wrap: wrap;
        }
        .services {
          font-family: Manrope;
          @media only screen and (max-width: 992px) {
            width: 50%;
            margin-bottom: 30px;
          }
          p {
            color: $primary;
            font-weight: 800;
          }
          ul {
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-self: stretch;
            margin: 0px;
            padding: 0px;
            li {
              list-style-type: none;
              color: $black;
              font-size: 14px;
              font-weight: 500;
              &:hover {
                color: $secondary;
              }
            }
          }
        }
      }
      .footerRightContent {
        flex: 1;
        box-shadow: 0px 20px 25px 1px rgba(20, 34, 108, 0.1);
        padding: 40px;
        flex-direction: column;
        font-family: Manrope;
        border-radius: 24px;
        @media only screen and (max-width: 992px) {
          padding: 20px 15px;
        }
        span {
          color: $primary;
          font-size: 16px;
          font-weight: 800;
        }
        .footerhead {
          display: flex;
          flex-direction: column;
          margin: 24px 0px;
          gap: 12px;

          .address {
            color: $black;
            font-weight: 500;
          }
          p {
            color: #09547e;
            font-weight: 800;
            margin: 0px;
          }
          h6 {
            color: $black;
          }
        }

        .socialIcon {
          display: flex;
          align-items: center;
          gap: 12px;
        }
      }
    }
    .footerCopyRight {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 20px 25px 10px rgba(20, 34, 108, 0.1);
      padding: 32px 40px;
      margin: 24px 0px;
      border-radius: 24px;
      @media only screen and (max-width: 992px) {
        padding: 20px 15px;
      }
      img {
        @media screen and (max-width: 567px) {
          width: 100px;
        }
        @media screen and (max-width: 320px) {
          width: 82px;
        }
      }
      .footerCopyLeft {
        display: flex;
        align-items: center;
        img {
          width: 125px;
          @media only screen and (max-width: 480px) {
            width: 100px;
          }
        }
      }
      .footerCopyRight1 {
        span {
          margin: 0px;
          font-family: Manrope;
          font-weight: 600;
          font-size: 12px;
          // @media screen and (max-width: 567px) {
          //   font-size: 14px;
          // }
          @media screen and (max-width: 320px) {
            font-size: 10px;
          }
        }
      }
    }
  }
}
